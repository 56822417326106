import { Button, Col, Modal, Row } from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from 'context/AppContext';
import { ModalProps } from 'models/CommonType';
import SignUp from '.';

interface SignUpModalProps extends ModalProps {
  loginClickHandler?: () => void;
}

const SignUpModal: React.FC<SignUpModalProps> = ({
  isOpen: open = true,
  closeHandler,
  loginClickHandler,
}) => {
  const { t } = useTranslation();
  const { authentication } = useContext(AppContext);
  const { setAuthKey } = authentication;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [key, setKey] = useState('');

  const onClose = useCallback(() => {
    setAuthKey(key);
    setKey('');
    setIsOpen(false);
    closeHandler?.();
  }, [key, setAuthKey, closeHandler]);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Modal
      title={t('signup.title')}
      open={isOpen}
      onCancel={onClose}
      footer={key === '' ? [
        <Row key="login" justify="center">
          <Col>
            <Trans
              i18nKey="signup.loginTitle"
              components={{
                login: <Button type='link' size='small' onClick={loginClickHandler} />,
              }}
            />
          </Col>
        </Row>,
      ] : null}
    >
      <SignUp authKey={key} setAuthKey={setKey} closeHandler={onClose} />
    </Modal>
  );
};

export default SignUpModal;
