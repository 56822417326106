import { Button, Row } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { AppContext } from 'context/AppContext';
import { AuthResponse } from 'services/types/AuthTypes';
import { Error } from 'models/CommonType';
import LoginForm from './LoginForm';

interface LoginProps {
  closeHandler?: () => void;
  forgotPasswordClickHandler?: () => void;
}

const Login: React.FC<LoginProps> = ({
  closeHandler,
  forgotPasswordClickHandler,
}) => {
  const { t } = useTranslation();
  const { api, authentication } = useContext(AppContext);
  const { authKey, setAuthKey } = authentication;
  const [loginErrors, setLoginErrors] = useState<Error>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (authKey) {
      closeHandler?.();
    }
  }, [authKey, closeHandler]);

  const login = async ({ email, password }: Record<string, string>) => {
    const { error = {}, access_token: key }: AuthResponse = await api.auth.login({ email, password });
    setAuthKey(key ?? '');
    setLoginErrors(error);
    setIsLoading(false);
  };

  const clearErrors = () => {
    if (!isEmpty(loginErrors)) {
      setLoginErrors(() => ({}));
    }
  };

  return (
    <>
      <LoginForm
        login={login}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        loginErrors={loginErrors}
        clearErrors={clearErrors}
      />
      <Row justify="center">
        <Button type='link' size='small' onClick={forgotPasswordClickHandler}>
          {t('login.forgotPassword')}
        </Button>
      </Row>
    </>
  );
};

export default Login;
