import { Form, Input, Button } from 'antd';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from 'context/AppContext';
import CommonType from 'models/CommonType';
import { checkFormValid } from 'utils/utils';

interface ForgotPasswordFormProps {
  forgotPassword: (arg: Record<string, string>) => Promise<void>;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  forgotPasswordErrors: CommonType.Error;
  clearErrors: () => void;
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  forgotPassword,
  isLoading,
  setIsLoading,
  forgotPasswordErrors,
  clearErrors,
}) => {
  const { t } = useTranslation();
  const { modal } = useContext(AppContext);
  const [form] = Form.useForm();

  const onSubmit = (values: any) => {
    setIsLoading(true);
    forgotPassword(values);
  };

  useEffect(() => {
    if (!modal.authModalState.isOpen) {
      form.resetFields();
    }
  }, [modal.authModalState.isOpen, form]);

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      onFinish={onSubmit}
      onValuesChange={(_changedValues, _allValues) => clearErrors()}
    >
      <Form.Item
        name="email"
        label={t('forgotPassword.email')}
        validateStatus={forgotPasswordErrors['email'] ? 'error' : 'success'}
        help={forgotPasswordErrors['email'] ? t('forgotPassword.validation.invalidEmail') : null}
        rules={[
          { required: true, message: t('forgotPassword.validation.emailRequired') },
          { type: "email", message: t('forgotPassword.validation.invalidEmail') },
        ]}
      >
        <Input id="forgot_password_email" placeholder={t('forgotPassword.emailPlaceholder')} />
      </Form.Item>

      <Form.Item shouldUpdate>
        {({ isFieldsTouched, getFieldsError }) => {
          return (
            <Button
              loading={isLoading}
              disabled={!checkFormValid(forgotPasswordErrors) ||
                !isFieldsTouched(true) ||
                getFieldsError().filter(({ errors }) => errors.length)
                  .length > 0}
              type="primary"
              htmlType="submit"
              shape="round"
              style={{ marginTop: '20px' }}
              block
            >
              {t('forgotPassword.reset')}
            </Button>
          )
        }}
      </Form.Item>
    </Form>
  );
};

export default ForgotPasswordForm;
