import { Button, Row } from 'antd';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Player } from '@lottiefiles/react-lottie-player';
import { isEmpty } from 'lodash';

import verifyEmailLottie from 'assets/lotties/verify-your-email.json';

import { AppContext } from 'context/AppContext';
import { AuthResponse } from 'services/types/AuthTypes';
import { Error } from 'models/CommonType';
import SignUpForm from './SignUpForm';

interface SignUpProps {
  authKey: string;
  setAuthKey: (key: string) => void;
  closeHandler?: () => void;
}

const SignUp: React.FC<SignUpProps> = ({
  authKey,
  setAuthKey,
  closeHandler,
}) => {
  const { t } = useTranslation();
  const { api } = useContext(AppContext);
  const [registrationErrors, setRegistrationErrors] = useState<Error>({});
  const [isLoading, setIsLoading] = useState(false);

  const signup = async ({ email, password, confirm }: Record<string, string>) => {
    const { error = {}, key }: AuthResponse = await api.auth.registration({ email, password, confirm });
    setAuthKey(key ?? '');
    setRegistrationErrors(error);
    setIsLoading(false);
  };

  const clearErrors = () => {
    if (!isEmpty(registrationErrors)) {
      setRegistrationErrors(() => ({}));
    }
  };

  return (
    <>
      {authKey ? (
        <>
          <Row justify="center">
            <Player autoplay loop src={verifyEmailLottie} style={{ height: '200px', width: '200px' }} />
          </Row>
          <Row justify="center">
            <p>{t('signup.success')}</p>
          </Row>
          <Button
            type="primary"
            shape="round"
            style={{ margin: '10px 0' }}
            onClick={closeHandler}
            block
          >
            {t('common.ok')}
          </Button>
        </>
      ) : (
        <SignUpForm
          signup={signup}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          registrationErrors={registrationErrors}
          clearErrors={clearErrors}
        />
      )}
    </>
  );
};

export default SignUp;
