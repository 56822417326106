import useSWR from 'swr';

import { fetcherWithToken } from './ApiClient';
import { FollowRequest } from './types/FollowTypes';

export const useFollow = (req: FollowRequest) => {
  const shouldFetch = !!req.userId && !!req.userToken;

  const params = req.type === 'Follower'
    ? `followee=${req.userId}&page[number]=${req.page}`
    : `follower=${req.userId}&page[number]=${req.page}`

  const { data, error, isLoading } = useSWR(
    shouldFetch ? [`${process.env.REACT_APP_DOMAIN}/api/follows/?${params}`, req.userToken] : null,
    ([url, token]) => fetcherWithToken(url, token),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    });

  return {
    data: data,
    isLoading: isLoading,
    isError: error
  }
};
