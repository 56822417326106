import { createContext, useCallback, useEffect, useMemo, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { ModalProps } from "models/CommonType";
import ApiClient from "services/ApiClient";
import { User, UserDetailsResponse } from "services/types/ProfileTypes";

interface UserResult {
  isLoading: boolean;
  error: {
    message: string;
  } | null;
  data?: UserDetailsResponse;
  refetch?: () => Promise<UserResult>;
}

export const useStore = () => {
  // const navigate = useNavigate();

  // Need to fetch data to show Names in breadcrum if jump to task page directly and skip capaign details
  const getAuthKey = useCallback((): string => {
    const key = localStorage.getItem('key');

    return key ?? '';
  }, []);

  // Check on refresh if user is logged in
  const [authKey, setAuthKey] = useState<string>(getAuthKey());

  // The status of the authentication modal.
  const [authModalState, setAuthModalState] = useState<ModalProps>({ isOpen: false });

  /**
   * Opens the authentication modal.
   *
   * @param {Function} [closeHandler] - Optional callback function to be executed when the modal is closed.
   */
  const openAuthModal = useCallback((closeHandler?: () => void) => {
    setAuthModalState({ isOpen: true, closeHandler: closeHandler });
  }, []);

  // The status of the earned miles modal.
  const [earnedMilesModalOpen, setEarnedMilesModalOpen] = useState(false);

  // always save key to localstorage using this mthod
  const saveAuthKey = useCallback((key: string): void => {
    localStorage.setItem('key', key);
  }, []);

  useEffect(() => {
    saveAuthKey(authKey);
  }, [authKey, saveAuthKey]);

  const api: ApiClient = useMemo(() => {
    return new ApiClient(authKey);
  }, [authKey]);

  // fetch active user data
  const { data: userData, refetch }: UserResult = useQuery(
    ['profileDetails', { authKey }],
    async () => await api.profile.getUserDetails(),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: ({ results }: UserDetailsResponse) => {
        // const { first_name: firstName, profile, id }: User = results?.[0] || {};
        // const { average_volunteer_hours: averageVolunteerHours, birth_year: birthYear } = profile || {};

        // if (id && (!firstName || averageVolunteerHours === -2 || !birthYear)) {
        //   navigate('/');
        // }
      },
      onSettled(_, error) {
        if (error) {
          console.log('error: ', error);
        }
      },
    }
  );

  const { results: userResults = [], error } = userData || {};
  const userDetails: User = userResults[0];

  useEffect(() => {
    if (error) {
      // set authKey empty if any error in fetching User details
      setAuthKey(() => '');
    }
  }, [error]);

  return {
    api,
    authentication: {
      setAuthKey,
      getAuthKey,
      authKey,
    },
    commonData: {
      userDetails,
    },
    modal: {
      authModalState,
      setAuthModalState,
      openAuthModal,
      earnedMilesModalOpen,
      setEarnedMilesModalOpen,
    },
    refetchUserdetails: {
      refetch,
    },
  };
};

export const AppContext = createContext({} as ReturnType<typeof useStore>);
