import { Drawer } from 'antd';

import EditAvatar from './EditAvatar';
import EditBio from './EditBio';
import EditName from './EditName';
import EditUsername from './EditUsername';

export enum EditType { None, Avatar, Name, Username, Bio }

interface EditDrawerProps {
  type: EditType;
  closeHandler?: () => void;
}

const EditDrawer: React.FC<EditDrawerProps> = ({
  type,
  closeHandler,
}) => {
  const isXs = window.innerWidth < 576;

  return (
    <>
      <Drawer
        width={isXs ? undefined : 576}
        height={'100%'}
        placement={isXs ? 'top' : 'right'}
        closable={false}
        onClose={closeHandler}
        open={type !== EditType.None}
        bodyStyle={{ paddingTop: 0 }}
      >
        {type === EditType.Avatar && <EditAvatar closeHandler={closeHandler} />}
        {type === EditType.Bio && <EditBio closeHandler={closeHandler} />}
        {type === EditType.Name && <EditName closeHandler={closeHandler} />}
        {type === EditType.Username && <EditUsername closeHandler={closeHandler} />}
      </Drawer>
    </>
  );
};

export default EditDrawer;
