import { Button, Col, Row, Upload, UploadFile, UploadProps } from 'antd';
import { CloseOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from 'context/AppContext';
import { UploadChangeParam } from 'antd/lib/upload';

interface EditAvatarProps {
  closeHandler?: () => void;
}

const EditAvatar: React.FC<EditAvatarProps> = ({ closeHandler }) => {
  const { t } = useTranslation();
  const { authentication, commonData, refetchUserdetails } = useContext(AppContext);
  const { authKey } = authentication;
  const { userDetails } = commonData;
  const [isLoading, setIsLoading] = useState(false);

  const uploadUrl = `${process.env.REACT_APP_DOMAIN}/api/users/${userDetails?.id}/update_image/`;

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setIsLoading(true);
    } else if (info.file.status === 'done') {
      setTimeout(async () => {
        await refetchUserdetails.refetch();
        closeHandler?.();
      }, 800);
    }
  };

  return (
    <>
      <Row justify={'space-between'} style={{ margin: '24px 0' }}>
        <Col>
          <Button
            type="text"
            icon={<CloseOutlined />}
            loading={isLoading}
            onClick={closeHandler} />
        </Col>
        <Col style={{ fontSize: 18 }}>{t('#Profile.update_avatar')}</Col>
        <Col></Col>
      </Row>
      <Upload
        name="file"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action={uploadUrl}
        onChange={handleChange}
        headers={{ Authorization: `Bearer ${authKey}` }}
      >
        <div>
          {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>{t('common.upload')}</div>
        </div>
      </Upload>
    </>
  );
};

export default EditAvatar;
