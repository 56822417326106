import { Avatar, Card, Row, Skeleton } from 'antd';
import Meta from 'antd/lib/card/Meta';
import moment from 'moment';
import 'moment/locale/zh-hk';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AppContext } from 'context/AppContext';
import { MileEarnings } from 'services/types/ServiceHourTypes';
import i18n from 'utils/i18n';

interface EarnedMilesItemProps {
  mileEarnings?: MileEarnings;
}

const EarnedMilesItem: React.FC<EarnedMilesItemProps> = ({
  mileEarnings,
}) => {
  moment.locale(i18n.language);
  const { t } = useTranslation();
  const { modal } = useContext(AppContext);
  const { setEarnedMilesModalOpen } = modal;
  const isLoading = !mileEarnings;

  const className = isLoading ? 'disabled-link' : undefined;

  const pageUrl = mileEarnings?.campaign
    ? `/campaign?id=${mileEarnings.campaign.id}`
    : `/task?id=${mileEarnings?.task?.id}`;

  const imgUrl = mileEarnings?.campaign?.image_small_url || mileEarnings?.task?.image_small_url;

  return (
    <Link className={className} to={pageUrl} onClick={() => setEarnedMilesModalOpen(false)}>
      <Card bordered={false}>
        <Card.Grid hoverable={false} style={{ width: '75%', padding: 12, boxShadow: 'none' }}>
          <Skeleton loading={isLoading} avatar={{ shape: 'circle' }} paragraph={false} active>
            <Meta
              avatar={imgUrl && <Avatar src={imgUrl} size={'large'} />}
              title={<Row style={{ fontSize: 14 }}>{mileEarnings?.title}</Row>}
            ></Meta>
          </Skeleton>
        </Card.Grid>

        <Card.Grid hoverable={false} style={{ width: '25%', padding: 12, boxShadow: 'none' }}>
          <Row justify={'end'} style={{ marginBottom: 5 }}>
            <Skeleton loading={isLoading} paragraph={false} active>
              {mileEarnings?.amount} {t('common.mile', { count: mileEarnings?.amount ?? 0 })}
            </Skeleton>
          </Row>
          <Row justify={'end'} style={{ fontSize: 12, color: 'grey' }}>
            <Skeleton loading={isLoading} paragraph={false} active>
              {moment(mileEarnings?.created).format("DD MMM, YYYY")}
            </Skeleton>
          </Row>
        </Card.Grid>
      </Card>
    </Link>
  );
};

export default EarnedMilesItem;
