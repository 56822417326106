import { useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { isEmpty } from 'lodash';

import { AppContext } from 'context/AppContext';
import { useFollow } from 'services/FollowService';
import { Follow as FollowModel, FollowResult, FollowType } from 'services/types/FollowTypes';
import { User } from 'services/types/ProfileTypes';
import FollowItem from './FollowItem';
import { FollowActionType, followReducer } from './FollowReducer';

interface FollowProps {
  userId: string;
  type: FollowType;
}

const Follow: React.FC<FollowProps> = ({
  userId,
  type,
}) => {
  const { api, authentication } = useContext(AppContext);
  const { authKey } = authentication;
  const [follows, setFollows] = useState<FollowModel[]>([]);

  const [request, dispatchRequest] = useReducer(followReducer, {
    userId: userId,
    userToken: authKey,
    type: type,
    page: 1,
  });

  const { data, isLoading } = useFollow(request);

  const { hasMore } = useMemo(() => {
    if (!data) {
      return { hasMore: false };
    } else {
      const result = data as FollowResult;
      setFollows(prevFollows => [...prevFollows, ...result.results]);
      return { hasMore: result.meta.pagination.page < result.meta.pagination.pages }
    }
  }, [data]);

  const followHandler = async (user: User) => {
    const isFollowing = user.profile?.is_following ?? false;
    let isSuccess = false;
    if (isFollowing) {
      const { error = {} } = await api.profile.unfollowUser(user.id ?? '');
      isSuccess = isEmpty(error);
    } else {
      const { error = {} } = await api.profile.followUser(user.id ?? '');
      isSuccess = isEmpty(error);
    }
    if (isSuccess) {
      setFollows(prevFollows =>
        prevFollows.map(follow => {
          let prevUser = type === 'Follower' ? follow.follower : follow.followee;
          if (prevUser.id === user.id && prevUser.profile) {
            prevUser.profile.is_following = !isFollowing;
          }
          return follow;
        })
      );
    }
  };

  const nextPageHandler = useCallback(() => {
    if (hasMore) {
      dispatchRequest({ type: FollowActionType.ChangedPage });
    }
  }, [hasMore, dispatchRequest]);

  const observerRef = useRef(null);

  useEffect(() => {
    let observerRefValue: Element | null = null;

    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          nextPageHandler();
        }
      },
      { threshold: 1 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
      observerRefValue = observerRef.current;
    }

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
  }, [observerRef, nextPageHandler]);

  useEffect(() => {
    setFollows([]);
    dispatchRequest({
      type: FollowActionType.ChangedUserOrType,
      request: {
        userId: userId,
        userToken: authKey,
        type: type,
        page: 1,
      },
    });
  }, [userId, authKey, type]);

  return (
    <>
      {follows.map((item, i) =>
        <FollowItem
          key={i}
          user={type === 'Follower'
            ? item.follower
            : item.followee}
          followHandler={followHandler} />)}
      {isLoading
        ? <>{[...Array(3)].map((_e, i) => <FollowItem key={i} />)}</>
        : <div ref={observerRef}></div>}
    </>
  );
};

export default Follow;
