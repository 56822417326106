import useSWR from 'swr';

import { fetcherWithToken } from './ApiClient';
import { MileEarningsRequest } from './types/ServiceHourTypes';

export const useMileEarnings = (req: MileEarningsRequest) => {
  const shouldFetch = !!req.userId && !!req.userToken;

  const params = `user=${req.userId}&sort=-created&page[size]=${req.pageSize}&page[number]=${req.page}`

  const { data, error, isLoading } = useSWR(
    shouldFetch ? [`${process.env.REACT_APP_DOMAIN}/api/mile-earnings/?${params}`, req.userToken] : null,
    ([url, token]) => fetcherWithToken(url, token),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    });

  return {
    data: data,
    isLoading: isLoading,
    isError: error
  }
};
