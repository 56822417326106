import { Modal } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalProps } from 'models/CommonType';
import ForgotPassword from '.';

interface ForgotPasswordModalProps extends ModalProps {
  loginClickHandler?: () => void;
}

const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({
  isOpen: open = true,
  closeHandler,
  loginClickHandler,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClose = useCallback(() => {
    setIsOpen(false);
    closeHandler?.();
  }, [closeHandler]);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Modal
      title={t('forgotPassword.title')}
      open={isOpen}
      onCancel={onClose}
      footer={null}
    >
      <ForgotPassword loginClickHandler={loginClickHandler} />
    </Modal>
  );
};

export default ForgotPasswordModal;
