import { Button, Col, Form, Input, Row } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from 'context/AppContext';

interface EditNameProps {
  closeHandler?: () => void;
}

const EditName: React.FC<EditNameProps> = ({ closeHandler }) => {
  const { t } = useTranslation();
  const { api, commonData, refetchUserdetails } = useContext(AppContext);
  const { userDetails } = commonData;
  const [isLoading, setIsLoading] = useState(false);

  const updateName = async (formData: any) => {
    setIsLoading(true);
    await api.profile.updateUserDetails(userDetails?.id ?? '', {
      first_name: formData.first_name,
      last_name: formData.last_name,
    });
    await refetchUserdetails.refetch();
    setIsLoading(false);
    closeHandler?.();
  };

  return (
    <>
      <Form
        layout="vertical"
        requiredMark={false}
        onFinish={updateName}
      >
        <Row justify={'space-between'} style={{ margin: '24px 0' }}>
          <Col>
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={closeHandler} />
          </Col>
          <Col style={{ fontSize: 18 }}>{t('#Profile.correct_full_name')}</Col>
          <Col>
            <Button
              type="text"
              htmlType="submit"
              loading={isLoading}>{t('#Profile.done')}</Button>
          </Col>
        </Row>

        <Form.Item
          name="first_name"
          initialValue={userDetails?.first_name}>
          <Input placeholder={t('#Profile.first_name')} />
        </Form.Item>

        <Form.Item
          name="last_name"
          initialValue={userDetails?.last_name}>
          <Input placeholder={t('#Profile.last_name')} />
        </Form.Item>

        <Row justify={'center'} style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>
          {t('#Profile.correct_full_name_hint')}
        </Row>
      </Form>
    </>
  );
};

export default EditName;
