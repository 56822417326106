import { Button, Col, Form, Input, Row, Typography, notification } from 'antd';
import { CloseCircleOutlined, FileImageOutlined, SendOutlined } from '@ant-design/icons';
import { useCallback, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import styled from 'styled-components';

import { AppContext } from 'context/AppContext';
import { Campaign, Task } from 'models/CommonType';
import { Notification } from 'services/types/ProfileTypes';
import { SubmissionResponse } from 'services/types/TaskTypes';
import SubmissionModal from './SubmissionModal';
import SubmissionDrawer from './SubmissionDrawer';

const { Title } = Typography;

const Banner = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 1600px;
  padding: 40px 30px 16px;
  background-color: #F1F1FA;
`

interface SubmissionBannerProps {
  task: Task;
  campaign: Campaign;
  submitHandler?: () => void;
}

const SubmissionBanner: React.FC<SubmissionBannerProps> = ({
  task,
  campaign,
  submitHandler,
}) => {
  const { t } = useTranslation();
  const { api } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [answer, setAnswer] = useState('');
  const [submissionId, setSubmissionId] = useState<string>();
  const [approvedNotification, setApprovedNotification] = useState<Notification>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const addPhoto = task.task_type === 'A_COMMENT' ||
    task.task_type === 'A_LIKE' ||
    task.task_type === 'M_PHOTO';

  const drawerOpen = useCallback(() => setIsDrawerOpen(true), []);
  const drawerCloseHandler = useCallback(() => setIsDrawerOpen(false), []);
  const modalCloseHandler = useCallback(() => setSubmissionId(undefined), []);

  const onSubmit = async (values: any) => {
    setIsLoading(true);

    const submissionResponse: SubmissionResponse = await api.task.submitTaskAnswers({
      taskId: task.id,
      message: values.submission,
      images: values.images,
    });

    if (submissionResponse.is_approved) {
      const notification = (await api.profile.getNotifiactions())
        .results
        ?.find((notification) => notification.data?.object_id === submissionResponse.id);
      setApprovedNotification(notification);
    }

    setIsLoading(false);

    if (!isEmpty(submissionResponse.error)) {
      if (submissionResponse.error.data.code === 'already_submitted') {
        notification.open({
          message: t('#Task.error.already_submitted_title'),
          description: t('#Task.error.already_submitted'),
          icon: <CloseCircleOutlined style={{ color: '#eb2f96' }} />,
        });
      } else {
        notification.open({
          message: t('#Task.error.error_title'),
          description: submissionResponse.error.data.message,
          icon: <CloseCircleOutlined style={{ color: '#eb2f96' }} />,
        });
      }
    } else {
      setSubmissionId(submissionResponse.id);
    }
    submitHandler?.();
  };

  const component = () => {
    const isExpired = task.expired ? new Date(task.expired).getTime() < Date.now() : false;
    if (task.is_approved) {
      return (
        <Title level={5} style={{ paddingBottom: 24, textAlign: 'center', color: 'gray' }}>
          {t('#Task.approved')}
        </Title>
      );
    } else if (isExpired) {
      return (
        <Title level={5} style={{ paddingBottom: 24, textAlign: 'center', color: 'gray' }}>
          {t('#Task.expired')}
        </Title>
      );
    } else if (task.is_require_team) {
      return (
        <Title level={5} style={{ paddingBottom: 24, textAlign: 'center', color: 'gray' }}>
          {t('#Task.require_team', { min: campaign.min_team_member, max: campaign.max_team_member })}
        </Title>
      );
    } else if (task.prerequisite_task) {
      return (
        <Link to={`/task?id=${task.prerequisite_task.id}`}>
          <Button
            style={{
              width: '100%',
              height: 45,
              padding: 10,
              marginBottom: 20,
              fontWeight: 700,
              borderRadius: 10,
              color: '#FFF',
              backgroundColor: '#7DC579'
            }}>{t('#Task.prerequisite')}</Button>
        </Link>
      );
    } else if (!task.is_submitted) {
      return (
        <Form
          onFinish={onSubmit}
          onValuesChange={(_changedValues, allValues) => setAnswer(allValues.submission)}
        >
          <Row justify={'center'} gutter={8}>
            <Col flex={2} md={18} lg={15} xl={12}>
              <Form.Item name="submission">
                <Input
                  id="task_submission"
                  placeholder={t('#Task.submission_placeholder')}
                  size="large"
                  readOnly={addPhoto}
                  onClick={addPhoto ? drawerOpen : undefined} />
              </Form.Item>
            </Col>

            {addPhoto && <Col>
              <Form.Item>
                <Button shape="circle" size="large" icon={<FileImageOutlined />} onClick={drawerOpen} />
              </Form.Item>
            </Col>}

            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="circle"
                  size="large"
                  icon={<SendOutlined />}
                  disabled={answer === ''}
                  loading={isLoading}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      );
    }
    return undefined;
  };
  const bannerComponent = component();

  return (
    <>
      {bannerComponent && <>
        <div style={{ paddingBottom: 120 }} />
        <Banner>{bannerComponent}</Banner>
      </>}

      <SubmissionDrawer
        isOpen={isDrawerOpen}
        closeHandler={drawerCloseHandler}
        taskId={task.id}
        isLoading={isLoading}
        submitHandler={onSubmit} />

      <SubmissionModal
        isOpen={!!submissionId}
        submissionId={submissionId}
        approvedNotification={approvedNotification}
        closeHandler={modalCloseHandler} />
    </>
  );
}

export default SubmissionBanner;
