import { Button, Col, Form, Input, Row } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from 'context/AppContext';
import { Error } from 'models/CommonType';
import { isEmpty } from 'lodash';

interface EditUsernameProps {
  closeHandler?: () => void;
}

const EditUsername: React.FC<EditUsernameProps> = ({ closeHandler }) => {
  const { t } = useTranslation();
  const { api, commonData, refetchUserdetails } = useContext(AppContext);
  const { userDetails } = commonData;
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Error>({});

  const updateUsername = async (formData: any) => {
    setIsLoading(true);
    const { error = {} } = await api.profile.updateUserDetails(userDetails?.id ?? '', { username: formData.username });
    if (isEmpty(error)) {
      await refetchUserdetails.refetch();
      closeHandler?.();
    } else {
      setErrors(error);
      setIsLoading(false);
    }
  };

  const clearErrors = () => {
    setErrors({});
  };

  return (
    <>
      <Form
        layout="vertical"
        requiredMark={false}
        onFinish={updateUsername}
        onValuesChange={(_changedValues, _allValues) => clearErrors()}
      >
        <Row justify={'space-between'} style={{ margin: '24px 0' }}>
          <Col>
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={closeHandler} />
          </Col>
          <Col style={{ fontSize: 18 }}>{t('#Profile.update_username')}</Col>
          <Col>
            <Button
              type="text"
              htmlType="submit"
              loading={isLoading}>{t('#Profile.done')}</Button>
          </Col>
        </Row>
        <Form.Item
          name="username"
          initialValue={userDetails?.username}
          validateStatus={errors?.username ? 'error' : 'success'}
          help={errors?.username ? errors?.username.message : null}
          rules={[
            { required: true, message: t('#Profile.validation.username_too_short') },
            { min: 4, message: t('#Profile.validation.username_too_short') },
            { max: 20, message: t('#Profile.validation.username_too_long') },
            { pattern: RegExp("^[A-Za-z0-9_]+$"), message: t('#Profile.validation.username_invalid') },
          ]}
        >
          <Input maxLength={20} showCount />
        </Form.Item>
      </Form>

    </>
  );
};

export default EditUsername;
