import { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from "react-helmet";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { AppContext } from 'context/AppContext';
import { Campaign } from 'models/CommonType';
import { useCampaign } from 'services/CampaignService';
import { useTasks } from "utils/apis";
import { truncateString, urlify } from "utils/helpers";

import Download from 'components/Download';

import CampaignJoinBanner from './components/CampaignJoinBanner';
import OrganizerBox from './components/OrganizerBox';
import TaskList from './components/TaskList';

import './CampaignPage.css';

const CampaignInfo = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #eee;
  padding: 100px;

  @media only screen and (max-width: 1200px) {
    padding: 0;
    flex-direction: column;
  }
`;

const CampaignTitle = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 100px;
  padding-right: 100px;

  @media only screen and (max-width: 1200px) {
    padding: 50px 100px 50px 100px;
  }

  @media only screen and (max-width: 800px) {
    padding: 30px 20px 30px 20px;
  }
`;

const CampaignImg = styled.img`
  height: 500px;
  max-width: 60%;
  object-fit: cover;

  @media only screen and (max-width: 1200px) {
    max-width: 1200px;
    height: 400px;
  }
`

const CampaignPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { authentication } = useContext(AppContext);
  const { authKey } = authentication;
  const [searchParams] = useSearchParams();

  const campaignId = searchParams.get("id") || ''
  const { data: campaignData, isError: campaignError, mutate: campaignMutate } = useCampaign(campaignId, authKey)
  const { data: tasks } = useTasks(campaignId)
  const campaign = campaignData as Campaign

  useEffect(() => {
    if (!isEmpty(campaignError)) {
      navigate('/');
    }
  }, [campaignError, navigate]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{campaign.name}</title>
        <meta name="description" content={campaign.description} />
        <meta name="author" content={`${campaign.user?.first_name} ${campaign.user?.last_name}`} />

        {/* <meta property="og:url" content="" /> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={campaign.name} />
        <meta property="og:description" content={campaign.description} />
        <meta property="og:site_name" content="Goodmates" />
        <meta property="og:image" content={campaign.user?.profile?.image_small_url} />
      </Helmet>

      <CampaignInfo>
        <CampaignImg src={campaign.image_url} />

        <CampaignTitle>
          <div className="CampaignPageTitle">{campaign.name}</div>
          <div>
            <div className="CampaignPageLabel">{t('#Campaign.description')}</div>
            {/* <div className="CampaignPageDescription">{urlify(campaign.description)}</div> */}
            <div
              className="CampaignPageDescription"
              dangerouslySetInnerHTML={{ __html: urlify(campaign.description) }}
            />
          </div>
        </CampaignTitle>
      </CampaignInfo>

      <div className="CampaignPage">
        <div className="CampaignPageLabel">{t('#Campaign.organizer')}</div>

        <OrganizerBox
          avatar={campaign.user?.profile?.image_small_url}
          user={campaign.user}
          bio={truncateString(campaign.user?.profile?.bio ?? '', 200)}
        />

        <div className="CampaignPageLabel">{t('#Campaign.tasks')}</div>

        {!campaign.is_private ? <TaskList tasks={tasks} /> : <Download />}
      </div>

      {!isEmpty(campaign) && !campaign.is_private && !campaign.is_joined &&
        <CampaignJoinBanner campaign={campaign} refetchCampaign={campaignMutate} />}
    </div>
  );
}

export default CampaignPage;
