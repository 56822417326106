import { MileEarningsRequest } from "services/types/ServiceHourTypes";

export enum EarnedMilesActionType {
  ChangedUser = "changed_user",
  ChangedPage = "changed_page",
};

export type EarnedMilesAction = {
  type: EarnedMilesActionType;
  request?: MileEarningsRequest;
};

export const earnedMilesReducer = (state: MileEarningsRequest, action: EarnedMilesAction) => {
  switch (action.type) {
    case EarnedMilesActionType.ChangedUser:
      return action.request
        ? {
          userId: action.request.userId,
          userToken: action.request.userToken,
          pageSize: action.request.pageSize,
          page: 1,
        }
        : state;
    case EarnedMilesActionType.ChangedPage:
      return {
        ...state,
        page: state.page + 1,
      };
    default:
      return state;
  }
};
