import { Skeleton } from 'antd';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Task } from 'models/CommonType';
import { truncateString } from "utils/helpers";
import './SkeletonItem.css'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid #eee;

  @media only screen and (max-width: 575px) {
    flex-direction: column;
    padding: 10px;
  }
`;

const ImageContainer = styled.div`
  line-height: 1.8rem;
  display: flex;
  flex-direction: column;
  margin: auto 0;

  @media only screen and (max-width: 575px) {
    margin: 0 auto;
  }
`;

const Image = styled.img`
  height: 180px;
  width: 180px;
  object-fit: contain;

  @media only screen and (max-width: 575px) {
    width: 100%;
  }
`;

const TextContainer = styled.div`
  line-height: 1.8rem;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const Name = styled.span`
  line-height: 3rem;
  font-size: 1rem;
`;

const Description = styled.span`
  color: gray;
  font-size: 0.9rem;
`;

const Meta = styled.span`
  color: gray;
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 4rem;
`;

interface Props {
  item?: Task;
  closeHandler?: () => void;
}

const TaskItem: React.FC<Props> = ({
  item,
  closeHandler,
}) => {
  const { t } = useTranslation();

  if (!item) {
    return (
      <Container>
        <ImageContainer className='SkeletonImg'>
          <Skeleton.Image active />
        </ImageContainer>
        <TextContainer className='SkeletonText'>
          <Skeleton active />
        </TextContainer>
      </Container>
    );
  }

  return (
    <Link to={`/task?id=${item.id}`} onClick={closeHandler}>
      <Container>
        <ImageContainer>
          <Image src={item.image_url} alt={item.name} />
        </ImageContainer>

        <TextContainer>
          <Name>{item.name}</Name>
          <Description>{truncateString(item.description, 200)}</Description>
          <Meta>{item.skill?.name} • {item.miles} {t('common.mile', { count: item.miles })}</Meta>
        </TextContainer>
      </Container>
    </Link>
  );
}

export default TaskItem;
