import { Button, Col, Drawer, Input, Modal, Row, Upload } from 'antd';
import { RcFile, UploadFile, UploadProps } from 'antd/lib/upload';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalProps } from 'models/CommonType';
import { AppContext } from 'context/AppContext';
import { isEmpty } from 'lodash';
import { SubmissionMediaResponse } from 'services/types/TaskTypes';

const { TextArea } = Input;

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

interface SubmissionDrawerProps extends ModalProps {
  taskId: string;
  isLoading: boolean;
  submitHandler?: (data: { submission: string; images?: string[]; }) => Promise<void>;
}

const SubmissionDrawer: React.FC<SubmissionDrawerProps> = ({
  isOpen: open = true,
  closeHandler,
  taskId,
  isLoading,
  submitHandler,
}) => {
  const isXs = window.innerWidth < 576;
  const { t } = useTranslation();
  const { authentication } = useContext(AppContext);
  const { authKey } = authentication;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [answer, setAnswer] = useState<string>('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [alertOpen, setAlertOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const uploadUrl = `${process.env.REACT_APP_DOMAIN}/api/images/`;

  const images = useMemo(
    () => fileList.filter((f) => f.response != null)
      .map((f) => (f.response as SubmissionMediaResponse).url),
    [fileList]);

  const isUploading = fileList.find((f) => f.status === 'uploading') != null;
  const submitDisabled = (answer === '' && isEmpty(images)) || isUploading;

  const onClose = useCallback(() => {
    setAnswer('');
    setFileList([]);
    setIsOpen(false);
    setAlertOpen(false);
    closeHandler?.();
  }, [closeHandler]);

  const onSubmit = async () => {
    await submitHandler?.({ submission: answer, images });
    onClose();
  }

  const onClick = () => {
    const errorFile = fileList.find((f) => f.status === 'error');
    if (errorFile != null) {
      setAlertOpen(true);
    } else {
      onSubmit();
    }
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  // Upload and Preview
  const handleCancel = () => {
    setPreviewImage('');
    setPreviewOpen(false);
  }

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => setFileList(newFileList);

  return (
    <>
      <Drawer
        width={isXs ? undefined : 576}
        height={'100%'}
        placement={isXs ? 'top' : 'right'}
        closable={false}
        open={isOpen}
        onClose={onClose}
        maskClosable={false}
        bodyStyle={{ paddingTop: 0 }}
      >
        <Row justify={'space-between'} style={{ margin: '24px 0' }}>
          <Col>
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={closeHandler} />
          </Col>
          <Col>
            <Button
              type="text"
              disabled={submitDisabled}
              loading={isLoading}
              onClick={onClick}>{t('#Task.submit')}</Button>
          </Col>
        </Row>
        <TextArea
          value={answer}
          onChange={e => setAnswer(e.target.value)}
          placeholder={t('#Task.submission_placeholder')}
          autoSize={{ minRows: 10, maxRows: 20 }}
          style={{ marginBottom: 20 }}
        />

        <Upload
          name='image'
          accept="image/png, image/jpeg"
          action={uploadUrl}
          data={{ name: `task-${taskId}` }}
          listType="picture-card"
          fileList={fileList}
          multiple={true}
          onPreview={handlePreview}
          onChange={handleChange}
          headers={{ Authorization: `Bearer ${authKey}` }}
        >
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>{t('common.upload')}</div>
          </div>
        </Upload>

        <Modal open={previewOpen} footer={null} onCancel={handleCancel}>
          <img alt="upload_image" style={{ width: '100%' }} src={previewImage} />
        </Modal>

        <Modal
          open={alertOpen}
          title={t('#Task.error.upload_failed_title')}
          cancelText={t('common.cancel')}
          okText={t('common.ok')}
          onCancel={() => setAlertOpen(false)}
          onOk={onSubmit}
          maskClosable={false}
        >
          {t('#Task.error.upload_failed')}
        </Modal>
      </Drawer>
    </>
  );
};

export default SubmissionDrawer;
