export enum SearchSort {
  Created = "-created",
  CreatedDesc = "created",
  Expired = "expired",
  ExpiredDesc = "-expired",
}

export enum SearchAction {
  ChangedKeyword = "changed_keyword",
  ChangedPage = "changed_page",
  ChangedSort = "changed_sort",
  DataLoaded = "data_loaded",
}

export type QueryOption = {
  keyword: string;
  limit: boolean;
  page: number;
  sort?: SearchSort;
};

export type QueryAction = {
  type: SearchAction;
  keyword?: string;
  sort?: SearchSort;
};

export const initialOption: QueryOption = {
  keyword: '', limit: false, page: 1
};

export const initialOptionLimit: QueryOption = {
  keyword: '', limit: true, page: 1
};

export const searchReducer = (state: QueryOption, action: QueryAction) => {
  switch (action.type) {
    case SearchAction.ChangedKeyword:
      return !action.keyword || action.keyword === state.keyword
        ? state
        : {
          ...state,
          keyword: action.keyword,
          page: 1,
        };
    case SearchAction.ChangedPage:
      return {
        ...state,
        page: state.page + 1,
      };
    case SearchAction.ChangedSort:
      return action.sort === state.sort
        ? state
        : {
          ...state,
          page: 1,
          sort: action.sort,
        };
    default:
      return state;
  }
};
