import { Avatar, Button, Card, Row, Skeleton, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { CSSProperties, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { User } from 'services/types/ProfileTypes';

const { Meta } = Card;
const { Paragraph } = Typography;

interface FollowItemProps {
  user?: User;
  followHandler?: (user: User) => Promise<void>;
}

const FollowItem: React.FC<FollowItemProps> = ({
  user,
  followHandler,
}) => {
  const { t } = useTranslation();
  const [btnLoading, setBtnLoading] = useState(false);

  const isLoading = !user;

  const imgUrl = user?.profile?.image_small_url;

  const isFollowing = user?.profile?.is_following ?? false;

  const buttonStyle: CSSProperties = isFollowing
    ? { padding: '4px 24px', fontWeight: 'bold', color: 'white', backgroundColor: '#6360FF' }
    : { padding: '4px 24px', fontWeight: 'bold', color: '#6360FF', borderColor: '#6360FF' };

  const onClick = async () => {
    if (user) {
      setBtnLoading(true);
      await followHandler?.(user);
      setBtnLoading(false);
    }
  };

  return (
    <Card bordered={false}>
      <Card.Grid hoverable={false} style={{ width: '75%', padding: 12, boxShadow: 'none' }}>
        <Skeleton loading={isLoading} avatar={{ shape: 'circle' }} paragraph={{ rows: 1 }} active>
          <Meta
            avatar={imgUrl
              ? <Avatar src={imgUrl} size={'large'} />
              : <Avatar icon={<UserOutlined />} size={'large'} />}
            title={
              <Row
                style={{ height: 22, fontSize: 14, fontWeight: 'bold', color: '#6360FF' }}>
                {`${user?.first_name} ${user?.last_name}`}
              </Row>}
            description={
              <Paragraph
                type={'secondary'}
                ellipsis={{ rows: 2 }}
                style={{ height: 38, fontSize: 12 }}>
                {user?.profile?.bio}
              </Paragraph>}
          ></Meta>
        </Skeleton>
      </Card.Grid>

      <Card.Grid hoverable={false} style={{ width: '25%', padding: 12, boxShadow: 'none' }}>
        <Row justify={'end'} style={{ marginBottom: 5 }}>
          {isLoading
            ? <Skeleton.Button shape={'round'} active />
            : <Button shape="round" style={buttonStyle} loading={btnLoading} onClick={onClick}>
              {isFollowing ? t('#Profile.following') : t('#Profile.follow')}
            </Button>}
        </Row>
      </Card.Grid>
    </Card>
  );
};

export default FollowItem;
