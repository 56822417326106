import { Form, Input, Button, Checkbox } from 'antd';
import { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from 'context/AppContext';
import CommonType from 'models/CommonType';
import { checkFormValid } from 'utils/utils';

interface LoginFormProps {
  login: (arg: Record<string, string>) => Promise<void>;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  loginErrors: CommonType.Error;
  clearErrors: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({
  login,
  isLoading,
  setIsLoading,
  loginErrors,
  clearErrors,
}) => {
  const { t } = useTranslation();
  const { modal } = useContext(AppContext);
  const [form] = Form.useForm();

  const onSubmit = (values: any) => {
    setIsLoading(true);
    login(values);
  };

  useEffect(() => {
    if (!modal.authModalState.isOpen) {
      form.resetFields();
    }
  }, [modal.authModalState.isOpen, form]);

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      onFinish={onSubmit}
      onValuesChange={(_changedValues, _allValues) => clearErrors()}
    >
      <Form.Item
        name="email"
        label={t('login.email')}
        rules={[
          { required: true, message: t('login.validation.required') },
          { type: "email", message: t('login.validation.invalidEmail') },
        ]}
      >
        <Input id="login_email" placeholder={t('login.emailPlaceholder')} />
      </Form.Item>

      <Form.Item
        name="password"
        label={t('login.password')}
        validateStatus={loginErrors?.password ? 'error' : 'success'}
        help={loginErrors?.password ? loginErrors?.password.message : null}
        rules={[
          { required: true, message: t('login.validation.required') },
          { min: 6, message: t('login.validation.invalidPassword') },
        ]}
      >
        <Input.Password id="login_password" placeholder={t('login.passwordPlaceholder')} />
      </Form.Item>

      <Form.Item
        name="policy"
        valuePropName="checked"
        rules={[
          {
            validator: (_, checked) => {
              if (!checked) {
                return Promise.reject(
                  new Error(t('login.validation.policyAccept'))
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Checkbox id="login_policy">
          <Trans
            i18nKey="login.policy"
            components={[
              <a target="_blank" rel="noreferrer" href="https://tnc.goodmates.org/">Terms of Service</a>,
              <a target="_blank" rel="noreferrer" href="https://privacy.goodmates.org/">Privacy Policy</a>,
            ]}
          />
        </Checkbox>
      </Form.Item>

      <Form.Item shouldUpdate>
        {({ isFieldsTouched, getFieldsError }) => {
          return (
            <Button
              loading={isLoading}
              disabled={!checkFormValid(loginErrors) ||
                !isFieldsTouched(true) ||
                getFieldsError().filter(({ errors }) => errors.length)
                  .length > 0}
              type="primary"
              htmlType="submit"
              shape="round"
              block
            >
              {t('login.title')}
            </Button>
          )
        }}
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
