import { Button, Col, Input, Row } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from 'context/AppContext';

const { TextArea } = Input;

interface EditBioProps {
  closeHandler?: () => void;
}

const EditBio: React.FC<EditBioProps> = ({ closeHandler }) => {
  const { t } = useTranslation();
  const { api, commonData, refetchUserdetails } = useContext(AppContext);
  const { userDetails } = commonData;
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(userDetails?.profile?.bio ?? '');

  const updateBio = async () => {
    setIsLoading(true);
    const userId = userDetails?.id ?? '';
    await api.profile.updateUserDetails(userId, { profile: { bio: value } });
    await refetchUserdetails.refetch();
    setIsLoading(false);
    closeHandler?.();
  };

  return (
    <>
      <Row justify={'space-between'} style={{ margin: '24px 0' }}>
        <Col>
          <Button
            type="text"
            icon={<CloseOutlined />}
            onClick={closeHandler} />
        </Col>
        <Col style={{ fontSize: 18 }}>{t('#Profile.update_bio')}</Col>
        <Col>
          <Button
            type="text"
            loading={isLoading}
            onClick={updateBio}>{t('#Profile.done')}</Button>
        </Col>
      </Row>
      <TextArea
        value={value}
        onChange={e => setValue(e.target.value)}
        placeholder={t('#Profile.add_bio')}
        autoSize={{ minRows: 10, maxRows: 30 }}
      />
    </>
  );
};

export default EditBio;
