import { Row } from "antd";
import { AppContext } from "context/AppContext";
import { useContext } from "react";
import { useTranslation } from 'react-i18next';

const LoginStatus: React.FC = () => {
  const { t } = useTranslation();
  const { authentication, commonData } = useContext(AppContext);
  const { authKey } = authentication;
  const { userDetails } = commonData || {};

  const userName: string = authKey ? userDetails?.first_name || userDetails?.username || '' : t('login.title');

  return (
    <Row style={{ minWidth: 150, padding: '0 5px' }}>{userName}</Row>
  );
};

export default LoginStatus;
