import { Button, Dropdown, Empty, MenuProps, Tabs, Typography } from "antd";
import { DownOutlined } from '@ant-design/icons';
import { ElementRef, useEffect, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  SearchAction,
  SearchSort,
  initialOption,
  initialOptionLimit,
  searchReducer
} from "./SearchReducer";
import SearchFilterTask from "./SearchFilterTask";
import SearchFilterCampaign from "./SearchFilterCampaign";
import SearchFilterAll from "./SearchFilterAll";

const { Title } = Typography;

const Center = styled.div`
  text-align: center;
`;

const SORT_ITEM_DEFAULT_KEY = "default";

enum Tab {
  All = "all",
  Campaigns = "campaigns",
  Tasks = "tasks",
}

interface Props {
  keyword: string;
  closeHandler?: () => void;
}

const SearchFilter: React.FC<Props> = ({
  keyword,
  closeHandler,
}) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(Tab.All);
  const [sort, setSort] = useState<SearchSort>();
  const [noData, setNoData] = useState(false);

  const [allQueryOption, dispatchAllQuery] = useReducer(
    searchReducer, initialOptionLimit);
  const [campaignQueryOption, dispatchCampaignQuery] = useReducer(
    searchReducer, initialOption);
  const [taskQueryOption, dispatchTaskQuery] = useReducer(
    searchReducer, initialOption);

  const [allCount, setAllCount] = useState<number>();
  const [campaignCount, setCampaignCount] = useState<number>();
  const [taskCount, setTaskCount] = useState<number>();

  const campaignTabRef = useRef<ElementRef<typeof SearchFilterCampaign>>(null);
  const taskTabRef = useRef<ElementRef<typeof SearchFilterTask>>(null);

  useEffect(() => {
    const queryAction = { type: SearchAction.ChangedKeyword, keyword };
    switch (tab) {
      case Tab.All:
        dispatchAllQuery(queryAction);
        break;
      case Tab.Campaigns:
        campaignTabRef.current?.onKeywordChanged(keyword);
        dispatchCampaignQuery(queryAction);
        break;
      case Tab.Tasks:
        taskTabRef.current?.onKeywordChanged(keyword);
        dispatchTaskQuery(queryAction);
        break;
    }
  }, [tab, keyword]);

  useEffect(() => {
    const queryAction = { type: SearchAction.ChangedSort, sort };
    switch (tab) {
      case Tab.Campaigns:
        campaignTabRef.current?.onSortChanged(sort);
        dispatchCampaignQuery(queryAction);
        break;
      case Tab.Tasks:
        taskTabRef.current?.onSortChanged(sort);
        dispatchTaskQuery(queryAction);
        break;
    }
  }, [tab, sort]);

  useEffect(() => {
    switch (tab) {
      case Tab.All:
        setNoData(allCount === 0);
        break;
      case Tab.Campaigns:
        setNoData(campaignCount === 0);
        break;
      case Tab.Tasks:
        setNoData(taskCount === 0);
        break;
    }
  }, [tab, allCount, campaignCount, taskCount]);

  const tabItems = [
    {
      key: Tab.All,
      label: t('search.all'),
      children: <SearchFilterAll
        queryOption={allQueryOption}
        setDataCount={setAllCount}
        closeHandler={closeHandler} />
    },
    {
      key: Tab.Campaigns,
      label: t('search.campaigns'),
      children: <SearchFilterCampaign
        ref={campaignTabRef}
        queryOption={campaignQueryOption}
        dispatchQuery={dispatchCampaignQuery}
        setDataCount={setCampaignCount}
        closeHandler={closeHandler} />
    },
    {
      key: Tab.Tasks,
      label: t('search.tasks'),
      children: <SearchFilterTask
        ref={taskTabRef}
        queryOption={taskQueryOption}
        dispatchQuery={dispatchTaskQuery}
        setDataCount={setTaskCount}
        closeHandler={closeHandler} />
    }
  ];

  const sortItems: MenuProps['items'] = [
    { label: (<Center>{t('search.sort_default')}</Center>), key: SORT_ITEM_DEFAULT_KEY },
    { label: (<Center>{t('search.sort_created_desc')}</Center>), key: SearchSort.CreatedDesc },
    { label: (<Center>{t('search.sort_created')}</Center>), key: SearchSort.Created },
    { label: (<Center>{t('search.sort_expired')}</Center>), key: SearchSort.Expired },
    { label: (<Center>{t('search.sort_expired_desc')}</Center>), key: SearchSort.ExpiredDesc }
  ];

  const onSortChanged = (key: string) => (key === SORT_ITEM_DEFAULT_KEY)
    ? setSort(undefined)
    : setSort(key as SearchSort);

  return (
    <>
      <Tabs
        activeKey={tab}
        tabBarExtraContent={tab !== Tab.All && !noData &&
          <Dropdown
            menu={{
              items: sortItems,
              selectable: true,
              selectedKeys: sort ? [sort] : [SORT_ITEM_DEFAULT_KEY],
              onSelect: (value) => onSortChanged(value.key),
            }}>
            <Button type="link">{t('search.sort')}<DownOutlined /></Button>
          </Dropdown>}
        tabBarStyle={{ position: 'sticky', top: 68, backgroundColor: '#fff', zIndex: 20 }}
        onTabClick={(key) => setTab(key as Tab)}
        items={tabItems} />

      {noData &&
        <Empty
          description={
            <>
              <Title level={5}>{t('search.no_result_title')}</Title>
              {keyword.length === 1
                ? <Center>{t('search.no_result_desc_one')}</Center>
                : <Center>{t('search.no_result_desc_other')}</Center>}
            </>
          }
          style={{ paddingTop: 40 }} />}
    </>
  );
}

export default SearchFilter;
