import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from 'context/AppContext';
import LoginModal from '../Login/LoginModal';
import SignUpModal from '../SignUp/SignUpModal';
import ForgotPasswordModal from '../ForgotPassword/ForgotPasswordModal';

import './AuthenticationModal.css'

enum AuthenticationModalType {
  None,
  Login,
  SignUp,
  ForgotPassword,
}

const AuthenticationModal: React.FC = () => {
  const { modal } = useContext(AppContext);
  const { authModalState, setAuthModalState } = modal;

  const [modalType, setModalType] = useState(AuthenticationModalType.None);
  const isOpenLoginModal = modalType === AuthenticationModalType.Login;
  const isOpenSignUpModal = modalType === AuthenticationModalType.SignUp;
  const isOpenForgotPasswordModal = modalType === AuthenticationModalType.ForgotPassword;

  useEffect(() => {
    if (authModalState.isOpen) {
      setModalType(AuthenticationModalType.Login);
    } else {
      setModalType(AuthenticationModalType.None);
    }
  }, [authModalState]);

  const closeHandler = useCallback(() => {
    authModalState.closeHandler?.();
    setAuthModalState({ isOpen: false, closeHandler: undefined });
  }, [authModalState, setAuthModalState]);

  const loginClickHandler = useCallback(() => {
    setModalType(AuthenticationModalType.Login);
  }, []);

  const signUpClickHandler = useCallback(() => {
    setModalType(AuthenticationModalType.SignUp);
  }, []);

  const forgotPasswordClickHandler = useCallback(() => {
    setModalType(AuthenticationModalType.ForgotPassword);
  }, []);

  return (
    <>
      <LoginModal
        isOpen={isOpenLoginModal}
        closeHandler={closeHandler}
        signUpClickHandler={signUpClickHandler}
        forgotPasswordClickHandler={forgotPasswordClickHandler}
      />
      <SignUpModal
        isOpen={isOpenSignUpModal}
        closeHandler={closeHandler}
        loginClickHandler={loginClickHandler}
      />
      <ForgotPasswordModal
        isOpen={isOpenForgotPasswordModal}
        closeHandler={closeHandler}
        loginClickHandler={loginClickHandler}
      />
    </>
  );
};

export default AuthenticationModal;
