import { useCallback, useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { AppContext } from 'context/AppContext';

export interface NavigateProps {
  from?: string;
}

const PrivateRoute = () => {
  const navigate = useNavigate();
  const { authentication, modal } = useContext(AppContext);
  const { authKey } = authentication;
  const { openAuthModal } = modal;

  const goBack = useCallback(() => navigate(-1), [navigate]);

  useEffect(() => {
    if (!authKey) {
      openAuthModal(goBack);
    }
  }, [authKey, openAuthModal, goBack]);

  // If authorized, return an outlet that will render child elements
  // If not, open login modal. On login, it will display profile view
  return (
    <>
      <Outlet />
    </>
  );
};

export default PrivateRoute;
