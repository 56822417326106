import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  iOSLink,
  androidLink,
  goToApp,
} from "utils/helpers";

import appleStoreImg from './../assets/app-store.png';
import googlePlayImg from './../assets/google-play.png';

const Container = styled.div`
  background-color: #f7f7ef;
  display: flex;
  flex-direction: row;
  padding: 50px;
  padding-left: 150px;
  border-radius: 10px;
  justify-content: space-between;

  font-size: 1rem;

  @media only screen and (max-width: 1200px) {
    padding-left: 50px;
  }

  @media only screen and (max-width: 800px) {
    margin: 0px;
    padding: 50px;
    flex-direction: column;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  padding-top: 8px;

  @media only screen and (max-width: 800px) {
    align-items: center;
    text-align: center;
  }
`;

const DownloadIcon = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 50px;

  @media only screen and (max-width: 800px) {
    align-items: center;
    text-align: center;
    width: 100%;
    margin: 0px;
    margin-top: 50px;
  }
`;

const RegisterButton = styled.button`
  display: flex;
  justify-content: center;
  width: 120px;
  padding: 10px;
  background-color: #60865B;
  color: white;
  font-size: 0.9rem;
  font-weight: bold;
  margin-top: 25px;

  @media only screen and (max-width: 800px) {
    margin-top: 10px;
  }
`;

const Image = styled.img`
  display: flex;
  flex-direction: column;

  width: 160px;
  margin-top: 20px;

  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`;

const Download: FC = () => {

  const { t } = useTranslation();

  const openApp = useCallback(() => {
    goToApp();
  }, []);

  return (
    <Container>
      <Description>
        <p>{t('#Campaign.goodmates_description')}</p>
        <p>{t('#Campaign.download_and_join_campaign')}</p>

        <RegisterButton onClick={() => openApp()}>{t("#Campaign.register")}</RegisterButton>
      </Description>

      <DownloadIcon>
        <h2>Goodmates App</h2>
        <span>{t('#Campaign.goodmates_slogan')}</span>
        <a href={iOSLink}>
          <Image src={appleStoreImg} alt="goodmates-iOS" />
        </a>
        <a href={androidLink}>
          <Image src={googlePlayImg} alt="goodmates-Android" />
        </a>
      </DownloadIcon>
    </Container>
  );
}

export default Download;
