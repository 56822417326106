import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Avatar, Carousel, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { HeartOutlined, MessageOutlined } from '@ant-design/icons';

import { goToApp, truncateString } from "utils/helpers";

import Download from './Download';

const Container = styled.div`
  display: flex;
  border: 1px solid #eee;
  flex-direction: column;
  padding: 15px;
`;

const Submittor = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Name = styled.span`
  line-height: 3rem;
  font-size: 1rem;
  padding-left: 15px;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  @media only screen and (max-width: 1200px) {
    padding-top: 30px;
  }
`;

const Image = styled.img`
  max-height: 300px;
  object-fit: contain;

  @media only screen and (max-width: 1200px) {
    width: 120px;
  }
`;

const Description = styled.span`
  color: gray;
  font-size: 0.9rem;
  padding-top: 30px;
`;

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  width: 60px;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const LikeComment = styled.a`
  display: flex;
  flex-direction: row;
`;

const Meta = styled.span`
  line-height: 3rem;
  color: gray;
  font-size: 0.9rem;
  font-weight: bold;
  margin-right: 15px;
`;

const SubmissionItem = ({ item, ...props }) => {
  const { t } = useTranslation();
  const [modal2Visible, setModal2Visible] = useState(false);

  const handleGoToApp = useCallback(() => {
    setModal2Visible(true);
    setTimeout(() => goToApp(false), 3000);
  }, []);

  return (
    <Container>
      <Submittor>
        <div>
          <Avatar size="large" src={item.user?.profile?.image_small_url} />
          <Name>{`${item.user?.first_name} ${item.user?.last_name}`}</Name>
        </div>
      </Submittor>

      <div>
        {item.images.length > 0 && <ImageContainer>
          <Carousel autoplay>
            {item.images.map(i =>
              <Image
                key={item.images[0].image_url}
                src={item.images[0].image_url}
                alt={item.name}
              />
            )}
          </Carousel>
        </ImageContainer>}
      </div>

      <Description>{truncateString(item.message, 200)}</Description>

      <Icons>
        <HeartOutlined style={{ fontSize: '26px' }} onClick={() => handleGoToApp()} />
        <MessageOutlined style={{ fontSize: '24px' }} onClick={() => handleGoToApp()} />
      </Icons>

      <LikeComment onClick={() => handleGoToApp()}>
        <Meta>{item.like_count} {t('#Task.like', { count: item.like_count })}</Meta>
        <Meta>{item.comment_count} {t('#Task.comment', { count: item.comment_count })}</Meta>
      </LikeComment>

      <Modal
        centered
        open={modal2Visible}
        footer={null}
        width={720}
        onCancel={() => setModal2Visible(false)}
      >
        <Download />
      </Modal>
    </Container>
  );
}

export default SubmissionItem;
