import { Modal } from 'antd';
import { useCallback, useContext } from 'react';

import { AppContext } from 'context/AppContext';
import EarnedMiles from '.';

const EarnedMilesModal: React.FC = () => {
  const { modal } = useContext(AppContext);
  const { earnedMilesModalOpen, setEarnedMilesModalOpen } = modal;

  const onClose = useCallback(() => {
    setEarnedMilesModalOpen(false);
  }, [setEarnedMilesModalOpen]);

  return (
    <Modal
      open={earnedMilesModalOpen}
      onCancel={onClose}
      footer={null}
    >
      <EarnedMiles closeHandler={onClose} />
    </Modal>
  );
};

export default EarnedMilesModal;
