import { Form, Input, Button, Checkbox } from 'antd';
import { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from 'context/AppContext';
import CommonType from 'models/CommonType';
import { checkFormValid } from 'utils/utils';

interface SignUpFormProps {
  signup: (arg: Record<string, string>) => Promise<void>;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  registrationErrors: CommonType.Error;
  clearErrors: () => void;
}

const SignUpForm: React.FC<SignUpFormProps> = ({
  signup,
  isLoading,
  setIsLoading,
  registrationErrors,
  clearErrors,
}) => {
  const { t } = useTranslation();
  const { modal } = useContext(AppContext);
  const [form] = Form.useForm();

  const getErrorMessage = (key: string) => {
    let error = registrationErrors[key];
    return error ? t(`signup.error.${key}.${error.code}`) : undefined;
  };

  const onSubmit = (values: any) => {
    setIsLoading(true);
    signup(values);
  };

  useEffect(() => {
    if (!modal.authModalState.isOpen) {
      form.resetFields();
    }
  }, [modal.authModalState.isOpen, form]);

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      onFinish={onSubmit}
      onValuesChange={(_changedValues, _allValues) => clearErrors()}
    >
      <Form.Item
        name="email"
        label={t('signup.email')}
        validateStatus={registrationErrors['email'] ? 'error' : 'success'}
        help={getErrorMessage('email')}
        rules={[
          { required: true, message: t('signup.validation.required') },
          { type: "email", message: t('signup.validation.invalidEmail') },
        ]}
      >
        <Input id="signup_email" placeholder={t('signup.emailPlaceholder')} />
      </Form.Item>

      <Form.Item
        name="password"
        label={t('signup.password')}
        validateStatus={registrationErrors['password'] ? 'error' : 'success'}
        help={getErrorMessage('password')}
        rules={[
          { required: true, message: t('signup.validation.required') },
          { min: 6, message: t('signup.validation.passwordPattern') },
        ]}
      >
        <Input.Password id="signup_password" placeholder={t('signup.passwordPlaceholder')} />
      </Form.Item>

      <Form.Item
        name="confirm"
        label={t('signup.confirm')}
        validateStatus={registrationErrors['confirm'] ? 'error' : 'success'}
        help={getErrorMessage('confirm')}
        dependencies={['password']}
        rules={[
          { required: true, message: t('signup.validation.required') },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(t('signup.validation.confirmPassword'))
              );
            },
          }),
        ]}
      >
        <Input.Password id="signup_confirm" placeholder={t('signup.confirmPlaceholder')} />
      </Form.Item>

      <Form.Item
        name="policy"
        valuePropName="checked"
        rules={[
          {
            validator: (_, checked) => {
              if (!checked) {
                return Promise.reject(
                  new Error(t('signup.validation.policyAccept'))
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Checkbox id="signup_policy">
          <Trans
            i18nKey="signup.policy"
            components={[
              <a target="_blank" rel="noreferrer" href="https://tnc.goodmates.org/">Terms of Service</a>,
              <a target="_blank" rel="noreferrer" href="https://privacy.goodmates.org/">Privacy Policy</a>,
            ]}
          />
        </Checkbox>
      </Form.Item>

      <Form.Item shouldUpdate>
        {({ isFieldsTouched, getFieldsError }) => {
          return (
            <Button
              loading={isLoading}
              disabled={!checkFormValid(registrationErrors) ||
                !isFieldsTouched(true) ||
                getFieldsError().filter(({ errors }) => errors.length)
                  .length > 0}
              type="primary"
              htmlType="submit"
              shape="round"
              block
            >
              {t('signup.submit')}
            </Button>
          )
        }}
      </Form.Item>
    </Form>
  );
};

export default SignUpForm;
