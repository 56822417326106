import { Col, Row, Skeleton, Typography } from 'antd';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Task } from 'models/CommonType';
import TaskItem from './TaskItem';

const { Title } = Typography;

interface Props {
  isLoading: boolean;
  tasks: Task[];
  count?: number;
  closeHandler?: () => void;
  nextPageHandler?: () => void;
}

const TaskList: React.FC<Props> = ({
  isLoading,
  tasks,
  count,
  closeHandler,
  nextPageHandler,
}) => {
  const { t } = useTranslation();

  const observerRef = useRef(null);

  useEffect(() => {
    let observerRefValue: Element | null = null;

    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          nextPageHandler?.();
        }
      },
      { threshold: 1 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
      observerRefValue = observerRef.current;
    }

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
  }, [observerRef, nextPageHandler]);

  return (
    <div>
      {isLoading
        ? <Skeleton title={{ width: 100 }} paragraph={false} active />
        : !!count &&
        <Title level={5} style={{ margin: '12px 0 20px' }}>
          {t('search.tasks_count', { count })}
        </Title>}
      <Row gutter={[32, 16]}>
        {tasks.map((item, i) => <Col key={i} span={24}>
          <TaskItem item={item} closeHandler={closeHandler} />
        </Col>)}
        {isLoading && <>
          <Col span={24}><TaskItem /></Col>
          <Col span={24}><TaskItem /></Col>
          <Col span={24}><TaskItem /></Col>
        </>}
      </Row >
      {!isLoading && tasks.length > 0 && <div ref={observerRef}></div>}
    </div>
  );
}

export default TaskList;
