import { Col, Row } from "antd";
import { useContext } from "react";

import { AppContext } from "context/AppContext";

import { ReactComponent as AwardIcon } from 'assets/svg/award.svg';

const MilesStatus: React.FC = () => {
  const { commonData } = useContext(AppContext);
  const { userDetails } = commonData || {};

  return (
    <Row gutter={10} onClick={() => { }}>
      <Col><AwardIcon width={20} height={20} /></Col>
      <Col style={{ fontWeight: 600 }}>{userDetails?.profile?.final_miles ?? 0}</Col>
    </Row>
  );
};

export default MilesStatus;
