import { Button, Col, Input, InputRef, Row } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";

import { ReactComponent as BackIcon } from 'assets/svg/navigate-before.svg';
import { useCampaigns } from "utils/apis";
import CampaignList from "./CampaignList";
import SearchFilter from "./SearchFilter";

interface SearchProps {
  closeHandler?: () => void;
}

const Search: React.FC<SearchProps> = ({
  closeHandler,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<InputRef>(null);
  const [keyword, setKeyword] = useState('');
  const debouncedSetKeyword = useMemo(() => debounce(setKeyword, 500), [setKeyword]);

  const { data: hotCampaigns, isLoading } = useCampaigns(true);

  useEffect(() => {
    // wait for animation to end
    setTimeout(() => inputRef.current?.focus({ cursor: 'start' }), 300);
  }, [inputRef]);

  return (
    <>
      <Row
        align={'middle'}
        style={{ position: 'sticky', top: 0, padding: '24px 0 12px', backgroundColor: '#fff', zIndex: 20 }}
      >
        <Col span={4}>
          <Button
            type="text"
            icon={<BackIcon />}
            onClick={closeHandler} />
        </Col>

        <Col span={16}>
          <Input
            placeholder={t('search.searchPlaceholder')}
            prefix={<SearchOutlined />}
            style={{ borderRadius: 10 }}
            onChange={(e) => debouncedSetKeyword(e.target.value)}
            ref={inputRef}
            allowClear
          />
        </Col>
      </Row>
      {keyword === '' &&
        <CampaignList
          title={t('search.hot_campaigns')}
          isLoading={isLoading}
          campaigns={hotCampaigns}
          closeHandler={closeHandler} />}
      {keyword !== '' &&
        <SearchFilter keyword={keyword} closeHandler={closeHandler} />}
    </>
  )
}

export default Search;
