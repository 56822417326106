import React, { FC } from 'react';

import styled from 'styled-components';
import { Link } from "react-router-dom";

import { truncateString } from "utils/helpers";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #eee;

  @media only screen and (max-width: 1200px) {
    flex-direction: row;
    padding: 10px;
  }

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    padding: 10px;
  }
`;

const ImageContainer = styled.div`
  line-height: 1.8rem;
  display: flex;
  flex-direction: column;
  background-color: #F5F5F5;

  @media only screen and (max-width: 1200px) {
    padding-left: 16px;
    padding-top: 30px;
    background-color: #FFF;
  }

  @media only screen and (max-width: 800px) {
    padding: 0;
    background-color: #F5F5F5;
  }
`;

const Image = styled.img`
  height: 300px;
  object-fit: contain;

  @media only screen and (max-width: 1200px) {
    height: 180px;
    width: 180px;
  }

  @media only screen and (max-width: 800px) {
    height: 300px;
    width: 100%;
  }
`;

const TextContainer = styled.div`
  line-height: 1.8rem;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const Name = styled.span`
  line-height: 3rem;
  font-size: 1rem;
`;

const Description = styled.span`
  color: gray;
  font-size: 0.9rem;
`;

interface Props {
  [item:string]: CampaignItemType;
}

interface CampaignItemType {
  id: string,
  image_url: string,
  name: string,
  description: string,
}

const CampaignItem: FC<Props> = ({ item, ...props }) => {
  return (
    <Link to={`/campaign?id=${item.id}`}>
      <Container>
        <ImageContainer>
          <Image src={item.image_url} alt={item.name} />
        </ImageContainer>

        <TextContainer>
          <Name>{item.name}</Name>
          <Description>{truncateString(item.description, 200)}</Description>
        </TextContainer>
      </Container>
    </Link>
  );
}

export default CampaignItem;
