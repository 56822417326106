import { Button, Col, Row, Typography } from 'antd';
import { useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { AppContext } from 'context/AppContext';
import { useMileEarnings } from 'services/ChallengeService';
import { MileEarnings, MileEarningsResult } from 'services/types/ServiceHourTypes';
import { EarnedMilesActionType, earnedMilesReducer } from './EarnedMilesReducer';
import EarnedMilesItem from './EarnedMilesItem';

import './EarnedMiles.css'

const { Title } = Typography;

interface EarnedMilesProps {
  closeHandler?: () => void;
}

const EarnedMiles: React.FC<EarnedMilesProps> = ({
  closeHandler,
}) => {
  const { t } = useTranslation();
  const { authentication, commonData } = useContext(AppContext);
  const { authKey } = authentication;
  const { userDetails } = commonData || {};
  const [mileEarnings, setMileEarnings] = useState<MileEarnings[]>([]);

  const [request, dispatchRequest] = useReducer(earnedMilesReducer, {
    userId: userDetails?.id ?? '',
    userToken: authKey,
    pageSize: 10,
    page: 1,
  });

  const { data, isLoading } = useMileEarnings(request);

  const { hasMore } = useMemo(() => {
    if (!data) {
      return { hasMore: false };
    } else {
      const result = data as MileEarningsResult;
      setMileEarnings(prevFollows => [...prevFollows, ...result.results]);
      return { hasMore: result.meta.pagination.page < result.meta.pagination.pages }
    }
  }, [data]);

  const nextPageHandler = useCallback(() => {
    if (hasMore) {
      dispatchRequest({ type: EarnedMilesActionType.ChangedPage });
    }
  }, [hasMore, dispatchRequest]);

  const observerRef = useRef(null);

  useEffect(() => {
    let observerRefValue: Element | null = null;

    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          nextPageHandler();
        }
      },
      { threshold: 1 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
      observerRefValue = observerRef.current;
    }

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
  }, [observerRef, nextPageHandler]);

  useEffect(() => {
    setMileEarnings([]);
    dispatchRequest({
      type: EarnedMilesActionType.ChangedUser,
      request: {
        userId: userDetails?.id ?? '',
        userToken: authKey,
        pageSize: 10,
        page: 1,
      },
    });
  }, [userDetails, authKey]);

  const emptyView = (<>
    <Row justify={'center'} style={{ margin: '100px 0 40px' }}>
      {t('earned_miles.empty')}
    </Row>
    <Row justify={'center'} style={{ marginBottom: 100 }}>
      <Button
        shape="round"
        style={{ color: 'white', backgroundColor: '#7dc57a' }}
        onClick={closeHandler}>
        {t('earned_miles.sure')}
      </Button>
    </Row>
  </>);

  return (
    <>
      {!isLoading && isEmpty(mileEarnings)
        ? emptyView
        : <>
          <Title level={5} style={{ margin: '24px 0 6px', textAlign: 'center' }}>
            {t('earned_miles.account_balance')}
          </Title>
          <Row justify={'center'} style={{ fontSize: 36, fontWeight: 'bold' }}>
            {userDetails?.profile?.final_miles ?? 0}
          </Row>
          <Row justify={'center'} style={{ padding: '12px 0 6px', fontWeight: 'bold' }}>/</Row>
          <Row justify={'center'} align={'middle'} gutter={12}>
            <Col style={{ fontSize: 12 }}>{t('earned_miles.miles_earned')}</Col>
            <Col style={{ fontSize: 24 }}>{userDetails?.profile?.miles ?? 0}</Col>
          </Row>
          <Title level={5} style={{ margin: '24px 12px 12px' }}>
            {t('earned_miles.miles_earnings')}
          </Title>
          {mileEarnings.map((item, i) => <EarnedMilesItem key={i} mileEarnings={item} />)}
        </>}
      {isLoading
        ? <div style={{ marginTop: 24 }}>{[...Array(3)].map((_e, i) => <EarnedMilesItem key={i} />)}</div>
        : <div ref={observerRef}></div>}
    </>
  );
};

export default EarnedMiles;
