import React, { FC } from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { Carousel } from 'antd';

const Container = styled.div`
  width: 100%;
  background-color: #F5F5F5;
`;

const Image = styled.img`
  width: 100%;
  max-height: 600px;
  object-fit: contain;

  @media only screen and (max-width: 1200px) {
    max-width: 1200px;
    height: 400px;
  }
`;

interface Props {
  campaigns: {
    id: string,
    image_url: string,
    name: string,
    description: string,
  }[]
}


const Banner: FC<Props> = ({ campaigns }) => {
  return (
    <Container>
      <Carousel autoplay effect="fade">
        {campaigns.map(i =>
          <Link key={i.image_url} to={`/campaign?id=${i.id}`}>
            <Image
              src={i.image_url}
              alt={i.name}
            />
          </Link>
        )}
      </Carousel>
    </Container>
  );
}

export default Banner;
