import { Modal, Row, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Player } from '@lottiefiles/react-lottie-player';

import { ModalProps } from 'models/CommonType';
import { Notification } from 'services/types/ProfileTypes';
import i18n from 'utils/i18n';

import submissionApproved from 'assets/lotties/submission-approved.json';
import submissionSuccess from 'assets/lotties/success.json';

const { Title } = Typography;

interface SubmissionModalProps extends ModalProps {
  submissionId?: string;
  approvedNotification?: Notification;
}

const SubmissionModal: React.FC<SubmissionModalProps> = ({
  isOpen: open = true,
  closeHandler,
  submissionId,
  approvedNotification,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isApproved = !!approvedNotification;
  const approvedTitle = i18n.language === 'zh' ? approvedNotification?.heading.zh : approvedNotification?.heading.en;
  const approvedMessage = i18n.language === 'zh' ? approvedNotification?.message.zh : approvedNotification?.message.en;

  const onClose = useCallback(() => {
    setIsOpen(false);
    closeHandler?.();
  }, [closeHandler]);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      footer={null}
    >
      <Row justify="center">
        <Player autoplay loop
          src={isApproved ? submissionApproved : submissionSuccess}
          style={{ height: '200px', width: '200px' }} />
      </Row>
      <Row justify="center">
        <Title level={4} style={{ padding: '12px 0', textAlign: 'center' }}>
          {isApproved ? approvedTitle : t('#Task.submitted_success_title')}
        </Title>
      </Row>
      <Row justify="center">
        <p style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>
          {isApproved ? approvedMessage : t('#Task.submitted_success', { id: submissionId ?? '' })}
        </p>
      </Row>
    </Modal>
  );
};

export default SubmissionModal;
