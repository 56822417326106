import React, { FC } from "react";
import styled from 'styled-components';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

import { useCampaigns } from "utils/apis";

import Banner from 'containers/Home/components/Banner';
import Download from 'components/Download';

import HotCampaignList from "./components/HotCampaignList";
import SubHeading from "./components/SubHeading";

const Container = styled.div`
  padding: 100px;

  @media only screen and (max-width: 1200px) {
    padding: 50px;
  }

  @media only screen and (max-width: 800px) {
    padding: 20px;
  }
`;


const Home: FC = () => {
  const { t } = useTranslation();

  const { data: hotCampaigns } = useCampaigns(true)
  const { data: otherCampaigns } = useCampaigns(false)

  const first_campaign = hotCampaigns.length > 0 ? hotCampaigns[0] : {
    name: '',
    description: '',
    user: {
      first_name: '', last_name: ''
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Goodmates ${first_campaign?.name}`}</title>
        <meta name="description" content={first_campaign.description} />
        <meta name="author" content={`${first_campaign?.user?.first_name} ${first_campaign?.user?.last_name}`} />

        {/* <meta property="og:url" content="" /> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={first_campaign.name} />
        <meta property="og:description" content={first_campaign.description} />
        <meta property="og:site_name" content="Goodmates" />
        <meta property="og:image" content={first_campaign?.user?.profile?.image_small_url} />
      </Helmet>

      {hotCampaigns.length > 0 && <Banner campaigns={hotCampaigns} />}
      {hotCampaigns.length === 0 && otherCampaigns.length > 0 && <Banner campaigns={otherCampaigns} />}
      <Container>

      <SubHeading>{t('#Home.hot_campaigns')}</SubHeading>

      <HotCampaignList campaigns={hotCampaigns} />

      <SubHeading>{t('#Home.others_campaigns')}</SubHeading>

      <HotCampaignList campaigns={otherCampaigns} />

      <Download />
    </Container>
    </div>

  );
}

export default Home;
