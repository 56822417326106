import { Avatar, Dropdown, Image, MenuProps, Row } from "antd";
import {
  LogoutOutlined,
  TranslationOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useCallback, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import { AppContext } from "context/AppContext";
import LoginStatus from 'components/Menu/LoginStatus';
import MilesStatus from "./MilesStatus";

const KEY_LOGIN_STATUS = 'login_status';
const KEY_MILES_STATUS = 'miles_status';
const KEY_MY_PROFILE = 'my_profile';
const KEY_LANGUAGE = 'language';
const KEY_LANGUAGE_EN = 'language_en';
const KEY_LANGUAGE_ZH = 'language_zh';
const KEY_LOGOUT = 'logout';

type MenuItem = Required<MenuProps>['items'][number];

const Menu: React.FC = () => {
  const { t } = useTranslation();
  const { authentication, commonData, modal } = useContext(AppContext);
  const { authKey, setAuthKey } = authentication;
  const { userDetails } = commonData;
  const { openAuthModal, setEarnedMilesModalOpen } = modal;
  const navigate = useNavigate();

  const avatarUrl = userDetails?.profile?.image_small_url;

  const loginClickHandler = useCallback(() => {
    openAuthModal();
  }, [openAuthModal]);

  const menuClickHandler = (key: string) => {
    switch (key) {
      case KEY_LOGIN_STATUS:
        if (!authKey) {
          loginClickHandler();
        } else {
          navigate('/my-profile');
        }
        break;
      case KEY_MILES_STATUS:
        setEarnedMilesModalOpen(true);
        break;
      case KEY_MY_PROFILE:
        if (!authKey) {
          loginClickHandler();
        } else {
          navigate('/my-profile');
        }
        break;
      case KEY_LOGOUT:
        setAuthKey('');
        break;
    }
  }

  const menuItems: MenuProps['items'] = [
    {
      key: KEY_LOGIN_STATUS,
      icon: avatarUrl ? <Avatar src={avatarUrl} size={"large"} /> : <Avatar icon={<UserOutlined />} size={"large"} />,
      label: <LoginStatus />,
    },
    ...(authKey ? [{
      key: KEY_MILES_STATUS,
      label: <Row><MilesStatus /></Row>,
    }] : []) as MenuItem[],
    { type: 'divider' },
    {
      key: KEY_MY_PROFILE,
      icon: <UserOutlined />,
      label: t('menu.my_profile'),
    },
    {
      key: KEY_LANGUAGE,
      icon: <TranslationOutlined />,
      label: t('menu.language'),
      children: [
        {
          key: KEY_LANGUAGE_EN,
          label: (
            <a rel="noopener noreferrer" href="/?locale=en">
              English
            </a>
          ),
        },
        {
          key: KEY_LANGUAGE_ZH,
          label: (
            <a rel="noopener noreferrer" href="/?locale=zh">
              中文
            </a>
          ),
        },
      ],
    },
    ...(authKey ? [
      { type: 'divider' },
      {
        key: KEY_LOGOUT,
        icon: <LogoutOutlined style={{ color: "#dc2626" }} />,
        label: <label style={{ color: "#dc2626" }}>{t('menu.logout')}</label>,
      }
    ] : []) as MenuItem[]
  ];

  return (
    <>
      <Dropdown
        menu={{ items: menuItems, onClick: ({ key }) => menuClickHandler(key) }}
        placement="bottomRight"
      >
        {avatarUrl
          ? <Avatar src={<Image src={avatarUrl} preview={false} style={{ width: 32, height: 32, marginBottom: 2, display: 'inline-block', verticalAlign: 'bottom' }} />} />
          : <Avatar icon={<UserOutlined />} />}
      </Dropdown>
    </>
  );
};

export default Menu;
