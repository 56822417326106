import axios, { AxiosInstance } from 'axios';

import i18n from 'utils/i18n';
import createAuthService, { AuthProps } from './AuthService';
import createProfileService, { ProfileProps } from './ProfileService';
import createCampaignService, { CampaignProps } from './CampaignService';
import createTaskService, { TaskProps } from './TaskService';

export default class ApiClient {
  baseURL = `${process.env.REACT_APP_DOMAIN}`;

  axiosClient: AxiosInstance;
  auth: AuthProps;
  profile: ProfileProps;
  campaign: CampaignProps;
  task: TaskProps;

  constructor(token: string = '') {
    this.axiosClient = axios.create({
      baseURL: this.baseURL,
      headers: {
        'Content-type': 'application/json',
        'Accept-Language': i18n.language,
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    });

    this.auth = createAuthService(this.baseURL, token);
    this.profile = createProfileService(this.axiosClient);
    this.campaign = createCampaignService(this.axiosClient);
    this.task = createTaskService(this.axiosClient);
  }
}

export const fetcherWithToken = (url: string, token: string) => axios.get(url, {
  headers: {
    'Content-type': 'application/json',
    'Accept-Language': i18n.language,
    ...(token && { Authorization: `Bearer ${token}` }),
  },
}).then(res => res.data);
