import { Error } from 'models/CommonType';

export const checkFormValid = (errors: Error) => {
  for (const error in errors) {
    if (errors?.[error]?.message) return false;
  }

  return true;
};

export const snakeToCamel = (str: string = '') =>
  str.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));
