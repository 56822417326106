import { Drawer } from 'antd';
import { useCallback, useState } from 'react';
import Search from '.';

interface SearchDrawerProps {
  isOpen: boolean;
  closeHandler?: () => void;
}

const SearchDrawer: React.FC<SearchDrawerProps> = ({
  isOpen: open = true,
  closeHandler,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(open);
  const isXs = window.innerWidth < 576;

  const onClose = useCallback(() => {
    setIsOpen(false);
    // wait for animation to end
    setTimeout(() => {
      closeHandler?.();
    }, 300);
  }, [closeHandler]);

  return (
    <>
      <Drawer
        width={isXs ? undefined : 576}
        height={'100%'}
        placement={isXs ? 'top' : 'right'}
        closable={false}
        onClose={onClose}
        open={isOpen}
        bodyStyle={{ paddingTop: 0 }}
      >
        <Search closeHandler={onClose} />
      </Drawer>
    </>
  );
};

export default SearchDrawer;
