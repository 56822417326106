import { Button, Col, Modal, Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ModalProps } from 'models/CommonType';
import Login from '.';

interface LoginModalProps extends ModalProps {
  signUpClickHandler?: () => void;
  forgotPasswordClickHandler?: () => void;
}

const LoginModal: React.FC<LoginModalProps> = ({
  isOpen: open = true,
  closeHandler,
  signUpClickHandler,
  forgotPasswordClickHandler,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(open);

  const onClose = useCallback(() => {
    setIsOpen(false);
    closeHandler?.();
  }, [closeHandler]);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Modal
      title={t('login.title')}
      open={isOpen}
      onCancel={onClose}
      footer={[
        <Row key="signup" justify="center">
          <Col>
            <Trans
              i18nKey="login.signUpTitle"
              components={{
                signup: <Button type='link' size='small' onClick={signUpClickHandler} />,
              }}
            />
          </Col>
        </Row>,
      ]}
    >
      <Login
        closeHandler={onClose}
        forgotPasswordClickHandler={forgotPasswordClickHandler}
      />
    </Modal>
  );
};

export default LoginModal;
