
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: 900;
  height: 120px;
`;

interface Props {
  children: string
}

const SubHeading = (props: Props) => {
  return (
    <Container>
      {props.children}
    </Container>
  );
}

export default SubHeading;
