
import { Row, Col, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Submission } from 'services/types/TaskTypes';
import SubmissionItem from './SubmissionItem';

const Container = styled.div`
  padding-bottom: 100px;
`;

interface Props {
  submissions: Submission[];
  mySubmission?: Submission;
}

const SubmissionList: React.FC<Props> = ({ submissions, mySubmission }) => {
  const { t } = useTranslation();
  return (
    <Container>
      {mySubmission &&
        <>
          <div className='CampaignPageLabel'>
            {t('#Task.my_submission')}
          </div>
          <Row gutter={[64, 0]}>
            <Col xs={24} xl={8}>
              <SubmissionItem item={mySubmission} />
            </Col>
            <Divider />
          </Row>
        </>
      }
      <Row gutter={[64, 64]}>
        {submissions.map((item, i) => <Col key={item.id} xs={24} xl={8}>
          <SubmissionItem item={item} />
        </Col>)}
      </Row >
    </Container>

  );
}

export default SubmissionList;
