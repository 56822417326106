import axios from 'axios';
import { errorFormatter } from 'services/serviceUtils/responseFormatter';
import { AuthResponse } from 'services/types/AuthTypes';
import { Error } from 'services/types/CommonTypes';

export interface AuthProps {
  login: (args: Record<string, string>) => Promise<AuthResponse>;
  registration: (args: { email: string; password: string; confirm: string }) => Promise<AuthResponse>;
}

function createAuthService(url: string, token: string): AuthProps {
  const axiosHeader = {
    'Content-Type': 'application/x-www-form-urlencoded',
    ...(token && { Authorization: `Bearer ${token}` }),
  };

  const axiosClient = axios.create({
    baseURL: url,
    headers: axiosHeader,
  });

  return {
    login: async ({ email, password }: Record<string, string>): Promise<AuthResponse> => {
      return axiosClient.post('/accounts/learnworlds_login', `email=${email}&password=${password}`).then(
        (res) => res?.data,
        (err) => {
          const { errors }: { errors: Error[] } = err?.response?.data || {};
          const formattedError = errorFormatter(errors, true);
          return { error: formattedError };
        }
      );
    },
    registration: async ({ email, password, confirm }: Record<string, string>): Promise<AuthResponse> => {
      return axiosClient.post('/api/auth/registration/', `email=${email}&password1=${password}&password2=${confirm}`).then(
        (res) => res?.data,
        (err) => {
          const { errors }: { errors: Error[] } = err?.response?.data || {};
          const formattedError = errorFormatter(errors);
          return { error: formattedError };
        }
      );
    },
  };
}

export default createAuthService;
