import { AxiosInstance } from "axios";
import useSWR from "swr";

import { errorFormatter } from 'services/serviceUtils/responseFormatter';
import { Error } from 'services/types/CommonTypes';

import { SubmissionResponse } from "services/types/TaskTypes";
import { fetcherWithToken } from "./ApiClient";

export const useTask = (taskId: string, token: string) => {
  const { data, error, isLoading, mutate } = useSWR(
    !!taskId ? [`${process.env.REACT_APP_DOMAIN}/api/tasks/${taskId}/`, token] : null,
    ([url, token]) => fetcherWithToken(url, token));

  return {
    data: data || {},
    isLoading: isLoading,
    isError: error,
    mutate: mutate,
  }
};

export const useSubmissions = (taskId: string, token: string) => {
  const { data, error, isLoading, mutate } = useSWR(
    !!taskId ? [`${process.env.REACT_APP_DOMAIN}/api/submissions/?task=${taskId}&sort=-is_pinned,-created&is_active=true`, token] : null,
    ([url, token]) => fetcherWithToken(url, token));

  return {
    data: data?.results || [],
    isLoading: isLoading,
    isError: error,
    mutate: mutate,
  }
};

export interface TaskProps {
  submitTaskAnswers: (data: { taskId: string; message: string; images?: string[]; }) => Promise<SubmissionResponse>;
}

function createTaskService(axiosClient: AxiosInstance): TaskProps {
  return {
    submitTaskAnswers: async ({ taskId, message, images }): Promise<SubmissionResponse> => {
      return axiosClient.post('/api/submissions/', {
        task: `${process.env.REACT_APP_DOMAIN}/api/tasks/${taskId}/`,
        message,
        images,
      }).then(
        (res) => res?.data,
        (err) => {
          const { errors }: { errors: Error[] } = err?.response?.data || {};
          const formattedError = errorFormatter(errors);
          return { error: formattedError };
        }
      );
    },
  };
}

export default createTaskService;
