import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { FollowType } from 'services/types/FollowTypes';
import Follow from '.';

interface FollowModalProps {
  userId?: string;
  type?: FollowType;
  closeHandler?: () => void;
}

const FollowModal: React.FC<FollowModalProps> = ({
  userId,
  type,
  closeHandler,
}) => {
  const { t } = useTranslation();
  const title = type === 'Follower'
    ? t('#Profile.followers')
    : type === 'Following'
      ? t('#Profile.following')
      : undefined;
  return (
    <Modal
      open={!!type}
      onCancel={closeHandler}
      title={title}
      footer={null}
    >
      {!!userId && !!type && <Follow userId={userId} type={type} />}
    </Modal>
  );
};

export default FollowModal;
