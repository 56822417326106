import { FollowRequest } from "services/types/FollowTypes";

export enum FollowActionType {
  ChangedUserOrType = "changed_user_or_type",
  ChangedPage = "changed_page",
};

export type FollowAction = {
  type: FollowActionType;
  request?: FollowRequest;
};

export const followReducer = (state: FollowRequest, action: FollowAction) => {
  switch (action.type) {
    case FollowActionType.ChangedUserOrType:
      return action.request
        ? {
          userId: action.request.userId,
          userToken: action.request.userToken,
          type: action.request.type,
          page: 1,
        }
        : state;
    case FollowActionType.ChangedPage:
      return {
        ...state,
        page: state.page + 1,
      };
    default:
      return state;
  }
};
