import React from 'react';
import styled from 'styled-components';
import { Avatar } from 'antd';

const Container = styled.div`
  background-color: #eee;
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 10px
`;

const NameBio = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
`;

const Name = styled.span`
  font-size: 1.1rem;
`;

const Bio = styled.span`
  font-size: 0.9rem;
  color: gray;
  margin-top: 5px;
`;

function OrganizerBox({ avatar, user, bio }) {
  return (
    <Container>
      <div>
        <Avatar size={60} src={avatar} />
      </div>
      <NameBio>
        <Name>{user && `${user.first_name} ${user.last_name}`}</Name>
        <Bio>{bio}</Bio>
      </NameBio>
    </Container>
  );
}

export default OrganizerBox;
