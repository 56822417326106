import { Button, Typography, notification } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Campaign } from "models/CommonType";
import { AppContext } from "context/AppContext";

const { Title } = Typography;

const Banner = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 1600px;
  padding: 40px 100px 16px;
  background-color: #F1F1FA;
`

interface CampaignJoinBannerProps {
  campaign: Campaign;
  refetchCampaign: () => void;
}

const CampaignJoinBanner: React.FC<CampaignJoinBannerProps> = ({
  campaign,
  refetchCampaign,
}) => {
  const { t } = useTranslation();
  const { api, authentication, modal } = useContext(AppContext);
  const { authKey } = authentication;
  const { openAuthModal } = modal;

  const [isLoading, setIsLoading] = useState(false);

  const onClick = async () => {
    if (authKey === '') {
      openAuthModal();
    } else if (campaign.id) {
      setIsLoading(true);

      const result = await api.campaign.joinCampaign(campaign.id);

      if (result.is_joined) {
        refetchCampaign();
        notification.open({
          message: t('#Campaign.join_success_title'),
          description: t('#Campaign.join_success'),
          icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
        });
      } else {
        notification.open({
          message: t('#Campaign.error.already_joined_title'),
          description: t('#Campaign.error.already_joined'),
          icon: <CloseCircleOutlined style={{ color: '#eb2f96' }} />,
        });
      }

      setIsLoading(false);
    }
  };

  const component = () => {
    const currentDate = Date.now();
    const isFuture = new Date(campaign.started).getTime() > currentDate;
    const isExpired = campaign.expired ? new Date(campaign.expired).getTime() < currentDate : false;

    if (isExpired) {
      return (
        <Title level={5} style={{ paddingBottom: 24, textAlign: 'center', color: 'gray' }}>
          {t('#Campaign.expired')}
        </Title>
      );
    } else if (isFuture) {
      const startedDate = new Date(campaign.started).getTime();
      const diffTime = Math.abs(startedDate - currentDate);
      const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
      if (diffHours > 23) {
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return (
          <Title level={5} style={{ paddingBottom: 24, textAlign: 'center', color: 'gray' }}>
            {t('#Campaign.will_start_day', { count: diffDays, day: diffDays })}
          </Title>
        );
      } else {
        return (
          <Title level={5} style={{ paddingBottom: 24, textAlign: 'center', color: 'gray' }}>
            {t('#Campaign.will_start_hour', { count: diffHours, hour: diffHours })}
          </Title>
        );
      }
    } else {
      return (
        <Button
          loading={isLoading}
          onClick={onClick}
          style={{
            width: '100%',
            height: 45,
            padding: 10,
            marginBottom: 20,
            fontWeight: 700,
            borderRadius: 10,
            color: '#FFF',
            backgroundColor: '#7DC579'
          }}>{t('#Campaign.join_now')}</Button>
      );
    }
  };
  const bannerComponent = component();

  return (
    <>
      <div style={{ paddingBottom: 120 }} />
      <Banner>{bannerComponent}</Banner>
    </>
  );
}

export default CampaignJoinBanner
