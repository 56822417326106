import useSWR from 'swr';

const fetcher = (...args) => fetch(...args).then(res => res.json());

const baseURL = `${process.env.REACT_APP_DOMAIN}`;

function useCampaigns(isPremium = false) {
  const { data, error } = useSWR(`${baseURL}/api/campaigns/?is_premium=${isPremium}`, fetcher)

  return {
    data: data?.results || [],
    isLoading: !error && !data,
    isError: error
  }
}

function useSearchCampaign({ keyword, limit, page = 1, sort = 'campaign_order' }) {
  const shouldFetch = !!keyword;
  const pageSize = limit ? `&page[size]=3` : '';
  const pageNum = page > 1 ? `&page[number]=${page}` : '';
  const { data, error, isLoading } = useSWR(shouldFetch ? `${baseURL}/api/campaigns/?search=${keyword}&sort=${sort}${pageSize}${pageNum}` : null, fetcher)

  return {
    data: data,
    isLoading: isLoading,
    isError: error
  }
}

function useTasks(campaignId) {
  const { data, error } = useSWR(`${baseURL}/api/tasks/?campaign=${campaignId}&sort=task_order`, fetcher)

  return {
    data: data?.results || [],
    isLoading: !error && !data,
    isError: error
  }
}

function useSearchTask({ keyword, limit, page = 1, sort = 'task_order' }) {
  const shouldFetch = !!keyword;
  const pageSize = limit ? `&page[size]=3` : '';
  const pageNum = page > 1 ? `&page[number]=${page}` : '';
  const { data, error, isLoading } = useSWR(shouldFetch ? `${baseURL}/api/tasks/?search=${keyword}&sort=${sort}${pageSize}${pageNum}` : null, fetcher)

  return {
    data: data,
    isLoading: isLoading,
    isError: error
  }
}

function useEnrollments(campaignId) {
  const { data, error } = useSWR(`${baseURL}/api/enrollments/?campaign=${campaignId}&sort=-miles`, fetcher)

  return {
    data: data?.results || [],
    isLoading: !error && !data,
    isError: error
  }
}

export {
  useCampaigns,
  useSearchCampaign,
  useEnrollments,
  useTasks,
  useSearchTask,
};
