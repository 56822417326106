import React from 'react';
import { Routes, Route, Outlet, } from "react-router-dom";

import { AppContext, useStore } from 'context/AppContext';
import PrivateRoute from 'components/Common/PrivateRoute';
import Heading from 'components/Heading';
import Campaign from 'containers/Campaign';
import Task from 'containers/Task';
import Home from 'containers/Home';
import Profile from 'containers/Profile';

import './utils/i18n';

const App: React.FC = () => {
  const store = useStore();

  return (
    <AppContext.Provider value={store}>
      <Heading />

      <Routes>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="my-profile">
            <Route index element={<Profile />} />
          </Route>
        </Route>

        <Route path="/" element={<Outlet />}>
          <Route path="/campaign" element={<Campaign />} />
          <Route path="/task" element={<Task />} />
          <Route index element={<Home />} />
        </Route>

      </Routes>
    </AppContext.Provider>
  );
};

export default App;
