import { Row } from "antd";
import { useEffect, useMemo } from "react";

import { CampaignResult, TaskResult } from "models/CommonType";
import { useSearchCampaign, useSearchTask } from "utils/apis";
import { QueryOption } from "./SearchReducer";
import CampaignList from "./CampaignList";
import TaskList from "./TaskList";

interface Props {
  queryOption: QueryOption;
  setDataCount: (count: number | undefined) => void;
  closeHandler?: () => void;
}

const SearchFilterAll: React.FC<Props> = ({
  queryOption,
  setDataCount,
  closeHandler,
}) => {
  const { data: campaignResult, isLoading: isCampaignLoading } = useSearchCampaign(queryOption);
  const { data: taskResult, isLoading: isTaskLoading } = useSearchTask(queryOption);

  const { campaigns, campaignsCount } = useMemo(() => {
    if (!campaignResult) {
      return { campaigns: [], campaignsCount: undefined };
    }
    return {
      campaigns: (campaignResult as CampaignResult).results,
      campaignsCount: (campaignResult as CampaignResult).meta.pagination.count,
    };
  }, [campaignResult]);

  const { tasks, tasksCount } = useMemo(() => {
    if (!taskResult) {
      return { tasks: [], tasksCount: undefined };
    }
    return {
      tasks: (taskResult as TaskResult).results,
      tasksCount: (taskResult as TaskResult).meta.pagination.count,
    };
  }, [taskResult]);

  useEffect(() => {
    if (campaignsCount == null && tasksCount == null) {
      setDataCount(undefined);
    } else if (campaignsCount == null) {
      setDataCount(tasksCount);
    } else if (tasksCount == null) {
      setDataCount(campaignsCount);
    } else {
      setDataCount(campaignsCount + tasksCount);
    }
  }, [setDataCount, campaignsCount, tasksCount]);

  return (
    <>
      {(isCampaignLoading || isTaskLoading
        || !!campaignsCount || !!tasksCount) &&
        <Row gutter={[0, 24]}>
          {(isCampaignLoading || !!campaignsCount) &&
            <CampaignList
              isLoading={isCampaignLoading}
              campaigns={campaigns}
              count={campaignsCount}
              closeHandler={closeHandler} />}
          {(isTaskLoading || !!tasksCount) &&
            <TaskList
              isLoading={isTaskLoading}
              tasks={tasks}
              count={tasksCount}
              closeHandler={closeHandler} />}
        </Row>}
    </>
  )
}

export default SearchFilterAll;
