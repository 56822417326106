import React, { FC } from 'react';
import { Row, Col } from 'antd';
import CampaignItem from './CampaignItem';

import './HotCampaignList.css'

interface Props {
  campaigns: []
}

const HotCampaignList: FC<Props> = ({ campaigns, ...props }) => {
  return (
    <div className='HotCampaignList'>
      <Row gutter={[32, 64]}>
        {campaigns.map((item, i) => <Col key={i} xs={24} xl={8}>
          <CampaignItem item={item} />
        </Col>)}
      </Row >
    </div>
  );
}

export default HotCampaignList;
