import { Error } from 'models/CommonType';
import { Error as ErrorResponse } from 'services/types/CommonTypes';
import { snakeToCamel } from 'utils/utils';

export const errorFormatter = (errors: ErrorResponse[] = [], isLogin?: boolean): Error => {
  const formattedError: Error = {};

  errors.forEach((error: ErrorResponse) => {
    const pointer = error?.source?.pointer || '';
    let field = pointer.split('/')[3];
    if (!field) field = pointer.split('/')[1];

    // for login all errors except email will go to password
    const fieldName = field === 'email' ? 'email' : 'password';

    formattedError[isLogin ? fieldName : snakeToCamel(field)] = {
      message: error?.detail,
      statusCode: error?.status,
      code: error?.code,
    };
  });

  return formattedError;
};
