import { Avatar, Button, Col, Row, Skeleton, Typography } from 'antd';
import { EditOutlined, UserOutlined } from '@ant-design/icons';
import { CSSProperties, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from 'context/AppContext';
import FollowModal from 'components/Follow/FollowModal';
import { FollowType } from 'services/types/FollowTypes';
import EditDrawer, { EditType } from './components/EditDrawer';

const { Title } = Typography;

const Profile: React.FC = () => {
  const { t } = useTranslation();
  const { commonData } = useContext(AppContext);
  const { userDetails } = commonData;
  const [editType, setEditType] = useState(EditType.None);
  const [followType, setFollowType] = useState<FollowType>();
  const isLoading = !userDetails;

  const editAvatarHandler = useCallback(() => setEditType(EditType.Avatar), []);
  const editBioHandler = useCallback(() => setEditType(EditType.Bio), []);
  const editNameHandler = useCallback(() => setEditType(EditType.Name), []);
  const editUsernameHandler = useCallback(() => setEditType(EditType.Username), []);
  const closeHandler = useCallback(() => setEditType(EditType.None), []);

  const hasFollower = (userDetails?.profile?.follower_count ?? 0) > 0;
  const hasFollowing = (userDetails?.profile?.following_count ?? 0) > 0;

  const followerStyle: CSSProperties | undefined = hasFollower ? { cursor: 'pointer' } : undefined;
  const followingStyle: CSSProperties | undefined = hasFollowing ? { cursor: 'pointer' } : undefined;

  const openFollowerModal = useCallback(() => {
    if (hasFollower) {
      setFollowType('Follower');
    }
  }, [hasFollower, setFollowType]);

  const openFollowingModal = useCallback(() => {
    if (hasFollowing) {
      setFollowType('Following');
    }
  }, [hasFollowing, setFollowType]);

  const closeFollowModal = useCallback(() => {
    setFollowType(undefined);
  }, [setFollowType]);

  return (
    <div style={{ maxWidth: 744, margin: '0 auto', padding: '32px 16px' }}>
      <Title level={3}>{t('#Profile.title')}</Title>

      <Row gutter={[32, 0]} style={{ marginTop: 32 }}>
        <Col style={{ marginBottom: 16, cursor: 'pointer' }}>
          <Skeleton
            loading={isLoading}
            avatar={{ shape: 'circle', size: 120 }}
            title={false}
            paragraph={false}
            active>
            {userDetails?.profile?.image_small_url
              ? <Avatar
                src={userDetails?.profile?.image_small_url}
                size={120}
                style={{ cursor: 'pointer' }}
                onClick={editAvatarHandler} />
              : <Avatar
                icon={<UserOutlined />}
                size={120}
                style={{ cursor: 'pointer' }}
                onClick={editAvatarHandler} />}
          </Skeleton>
        </Col>

        <Col>
          <Row>
            <Skeleton loading={isLoading} paragraph={false} active>
              <Title level={4}>{userDetails?.first_name} {userDetails?.last_name}</Title>
              <Button
                type='text'
                icon={<EditOutlined style={{ color: '#a4a4a4' }} />}
                onClick={editNameHandler} />
            </Skeleton>
          </Row>

          <Row>
            <Skeleton loading={isLoading} title={{ width: 100 }} paragraph={false} active>
              <Col style={{ paddingTop: 4 }}>@{userDetails?.username}</Col>
              <Button
                type='text'
                icon={<EditOutlined style={{ color: '#a4a4a4' }} />}
                onClick={editUsernameHandler} />
            </Skeleton>
          </Row>

          <Row gutter={[40, 0]} style={{ marginTop: 12 }}>
            <Col style={followerStyle} onClick={openFollowerModal}>
              <Skeleton loading={isLoading} paragraph={false} active>
                <Row justify={'center'} style={{ fontSize: 16, fontWeight: 'bold' }}>{userDetails?.profile?.follower_count}</Row>
              </Skeleton>
              <Row>{t('#Profile.followers')}</Row>
            </Col>
            <Col style={followingStyle} onClick={openFollowingModal}>
              <Skeleton loading={isLoading} paragraph={false} active>
                <Row justify={'center'} style={{ fontSize: 16, fontWeight: 'bold' }}>{userDetails?.profile?.following_count}</Row>
              </Skeleton>
              <Row>{t('#Profile.following')}</Row>
            </Col>
            <Col>
              <Skeleton loading={isLoading} paragraph={false} active>
                <Row justify={'center'} style={{ fontSize: 16, fontWeight: 'bold' }}>{userDetails?.profile?.liked_count}</Row>
              </Skeleton>
              <Row>{t('#Profile.liked')}</Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ marginTop: 40 }}>
        <Title level={5} style={{ paddingTop: 2 }}>{t('#Profile.bio')}</Title>
        {userDetails?.profile?.bio &&
          <Button
            type='text'
            icon={<EditOutlined style={{ color: '#a4a4a4' }} />}
            onClick={editBioHandler} />}
      </Row>
      <Row style={{ marginTop: 8, whiteSpace: 'pre-wrap', wordBreak: 'break-word', wordWrap: 'break-word' }}>
        <Skeleton loading={isLoading} title={false} paragraph={{ rows: 3 }} active>
          {userDetails?.profile?.bio
            ? userDetails?.profile?.bio
            : <Button onClick={editBioHandler}>{t('#Profile.add_bio')}</Button>}
        </Skeleton>
      </Row>

      <EditDrawer
        type={editType}
        closeHandler={closeHandler} />

      <FollowModal
        userId={userDetails?.id}
        type={followType}
        closeHandler={closeFollowModal} />

    </div>
  );
};

export default Profile;
