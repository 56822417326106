import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

import translationEN from './locales/en.json';
import translationZhHant from './locales/zh-Hant.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  zh: {
    translation: translationZhHant
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    resources,
    fallbackLng: "en",
    supportedLngs: ["en", "zh"],
    detection: {
      order: ['querystring', 'cookie', 'localStorage'],
      lookupQuerystring: 'locale',
    },
    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;
