import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import appleStoreImg from './../../../assets/app-store.png';
import googlePlayImg from './../../../assets/google-play.png';

const Container = styled.div`
  background-color: #f7f7ef;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 50px;
  font-size: 1rem;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  text-align: center;
`;

const DownloadIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  }
`;

const Image = styled.img`
  width: 160px;
  margin: 15px;
`;

const Download: FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Description>
        <p>{t('#Task.goodmates_description')}</p>
        <p>{t('#Task.download_and_join_campaign')}</p>
      </Description>

      <DownloadIcon>
        <a href="https://apps.apple.com/hk/app/goodmates/id1559340728">
          <Image src={appleStoreImg} alt="goodmates-iOS" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=org.goodmates">
          <Image src={googlePlayImg} alt="goodmates-Android" />
        </a>
      </DownloadIcon>
    </Container>
  );
}

export default Download;
