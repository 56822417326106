import React, { FC } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';

import TaskItem from './TaskItem';

const Container = styled.div`
  padding-bottom: 100px;
`;

interface Props {
  tasks: []
}

const TaskList: FC<Props> = ({ tasks, ...props }) => {
  return (
    <Container>
      <Row gutter={[32, 64]}>
        {tasks.map((item, i) => < Col key={i} xs={24} xl={8}>
          <TaskItem item={item} />
        </Col>)}
      </Row >
    </Container>
  );
}

export default TaskList;
