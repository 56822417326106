import { Button } from 'antd';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { ReactComponent as SuccessIcon } from 'assets/svg/success.svg';

import { AppContext } from 'context/AppContext';
import { VerifyEmailResponse } from 'services/types/ProfileTypes';
import { Error } from 'models/CommonType';
import ForgotPasswordForm from './ForgotPasswordForm';

interface ForgotPasswordProps {
  loginClickHandler?: () => void;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({
  loginClickHandler,
}) => {
  const { t } = useTranslation();
  const { api } = useContext(AppContext);
  const [forgotPasswordErrors, setForgotPasswordErrors] = useState<Error>({});
  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState<string>();

  const forgotPassword = async ({ email }: Record<string, string>) => {
    const { error = {}, detail }: VerifyEmailResponse = await api.profile.resetPasswordAndSendEmail(email);
    setDetail(detail);
    setForgotPasswordErrors(error);
    setIsLoading(false);
  };

  const clearErrors = () => {
    if (!isEmpty(forgotPasswordErrors)) {
      setForgotPasswordErrors(() => ({}));
    }
  };

  return (
    <>
      {detail ? (
        <div style={{ textAlign: 'center' }}>
          <SuccessIcon />
          <p style={{
            marginTop: '20px',
            fontSize: '18px',
            fontWeight: 'bold',
          }}
          >
            {t('forgotPassword.sent')}
          </p>
          <p style={{ color: '#64748b' }}>{t('forgotPassword.sentDesc')}</p>
          <Button
            type="primary"
            shape="round"
            style={{ margin: '20px 0' }}
            onClick={loginClickHandler}
            block
          >
            {t('forgotPassword.backToLogIn')}
          </Button>
        </div>
      ) : (
        <>
          <p>{t('forgotPassword.desc')}</p>
          <ForgotPasswordForm
            forgotPassword={forgotPassword}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            forgotPasswordErrors={forgotPasswordErrors}
            clearErrors={clearErrors}
          />
        </>
      )}
    </>
  );
};

export default ForgotPassword;
