import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from "react-helmet";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import Download from 'components/Download';
import { AppContext } from 'context/AppContext';
import { Campaign, Task as TaskModel } from 'models/CommonType';
import { useCampaign } from 'services/CampaignService';
import { useSubmissions, useTask } from 'services/TaskService';
import { Submission } from 'services/types/TaskTypes';
import { urlify } from "utils/helpers";

import SubmissionList from './components/SubmissionList';
import SubmissionBanner from './components/SubmissionBanner';

const BannerInfo = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #eee;
  padding: 100px;

  @media only screen and (max-width: 1200px) {
    padding: 0;
    flex-direction: column;
  }
`;

const BannerTitle = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 100px;
  padding-right: 100px;

  @media only screen and (max-width: 1200px) {
    padding: 50px 100px 50px 100px;
  }

  @media only screen and (max-width: 800px) {
    padding: 30px 20px 30px 20px;
  }
`;

const BannerImg = styled.img`
  height: 500px;
  max-width: 60%;
  object-fit: cover;

  @media only screen and (max-width: 1200px) {
    max-width: 1200px;
    height: 400px;
  }
`

const Content = styled.div`
  padding: 100px;

  @media only screen and (max-width: 1200px) {
    padding: 50px;
  }

  @media only screen and (max-width: 800px) {
    padding: 20px;
  }
`;

const Task: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { authentication, commonData } = useContext(AppContext);
  const { authKey } = authentication;

  const taskId = searchParams.get("id") || '';
  const { data: taskData, isError: taskError, mutate: taskMutate } = useTask(taskId, authKey);
  const { data: submissionsData, mutate: submissionsMutate } = useSubmissions(taskId, authKey);
  const task = taskData as TaskModel;
  const submissions = submissionsData as Submission[];
  const availableType = task.task_type === 'A_TEXT' ||
    task.task_type === 'M_TEXT' ||
    task.task_type === 'A_COMMENT' ||
    task.task_type === 'A_LIKE' ||
    task.task_type === 'M_PHOTO';

  useEffect(() => {
    if (!isEmpty(taskError)) {
      navigate('/');
    }
  }, [taskError, navigate]);

  const [showSubmission, setShowSubmission] = useState(false);

  // fetch campaign info
  const campaignId = useMemo(() => {
    if (isEmpty(task)) return '';

    const url = new URL(task.campaign);
    return url.pathname.split('/').filter(e => e).pop() ?? '';
  }, [task]);
  const { data: campaignData } = useCampaign(campaignId, authKey);
  const campaign = campaignData as Campaign;

  // fetch my submission
  const mySubmission = useMemo(() => {
    if (!task.is_submitted || isEmpty(submissions) || isEmpty(commonData.userDetails)) return undefined;

    return submissions.find((submission) => submission.user.id === commonData.userDetails.id);
  }, [task, submissions, commonData.userDetails]);

  // revalidation data after submit
  const submitHandler = useCallback(() => {
    taskMutate();
    submissionsMutate();
  }, [taskMutate, submissionsMutate]);

  // determine whether to display the submission banner
  useEffect(() => {
    if (isEmpty(campaign) || isEmpty(task)) {
      setShowSubmission(false);
    } else {
      setShowSubmission(campaign.is_joined && task.is_active && availableType);
    }
  }, [campaign, task, availableType]);

  return (
    <div className="TaskContainer">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{task.name}</title>
        <meta name="description" content={task.description} />

        {/* <meta property="og:url" content="" /> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={task.name} />
        <meta property="og:description" content={task.description} />
        <meta property="og:site_name" content="Goodmates" />
        <meta property="og:image" content={task.image_small_url} />
      </Helmet>

      <BannerInfo>
        <BannerImg src={task.image_url} />

        <BannerTitle>
          <div className="CampaignPageTitle">{task.name}</div>
          <div>
            <div className="CampaignPageLabel">{t('#Campaign.description')}</div>
            {/* <div className="CampaignPageDescription">{urlify(campaign.description)}</div> */}
            <div
              className="CampaignPageDescription"
              dangerouslySetInnerHTML={{ __html: urlify(task.description) }}
            />
          </div>
        </BannerTitle>
      </BannerInfo>

      <Content>
        <SubmissionList submissions={submissions} mySubmission={mySubmission} />

        {!availableType && <Download />}
      </Content>

      {showSubmission &&
        <SubmissionBanner task={task} campaign={campaign} submitHandler={submitHandler} />}
    </div>
  );
}

export default Task;
