import {
  Dispatch,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from "react";

import { Task, TaskResult } from "models/CommonType";
import { useSearchTask } from "utils/apis";
import { QueryAction, QueryOption, SearchAction, SearchSort } from "./SearchReducer";
import TaskList from "./TaskList";

interface Props {
  queryOption: QueryOption;
  dispatchQuery: Dispatch<QueryAction>;
  setDataCount: (count: number | undefined) => void;
  closeHandler?: () => void;
}

const SearchFilterTask: React.ForwardRefRenderFunction<any, Props> = ({
  queryOption,
  dispatchQuery,
  setDataCount,
  closeHandler,
}, ref) => {
  const [tasks, setTasks] = useState<Task[]>([]);

  useImperativeHandle(ref, () => {
    return {
      onKeywordChanged(keyword: string) {
        if (keyword !== queryOption.keyword) {
          setTasks([]);
        }
      },
      onSortChanged(sort?: SearchSort) {
        if (sort !== queryOption.sort) {
          setTasks([]);
        }
      }
    };
  }, [queryOption]);

  const { data, isLoading } = useSearchTask(queryOption);

  const { count, hasMore } = useMemo(() => {
    if (!data) {
      return { count: undefined, hasMore: false };
    }
    const result = (data as TaskResult);
    setTasks(prevTasks => [...prevTasks, ...result.results]);
    return {
      count: result.meta.pagination.count,
      hasMore: result.meta.pagination.page < result.meta.pagination.pages,
    };
  }, [data]);

  const nextPageHandler = useCallback(() => {
    if (hasMore) {
      dispatchQuery({ type: SearchAction.ChangedPage });
    }
  }, [hasMore, dispatchQuery]);

  useEffect(() => setDataCount(count), [setDataCount, count]);

  return (
    <>
      {(isLoading || !!count) &&
        <TaskList
          isLoading={isLoading}
          tasks={tasks}
          count={count}
          closeHandler={closeHandler}
          nextPageHandler={nextPageHandler} />}
    </>
  )
}

export default forwardRef(SearchFilterTask);
