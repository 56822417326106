import { Col, Row, Skeleton, Typography } from 'antd';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Campaign } from 'models/CommonType';
import CampaignItem from './CampaignItem';

const { Title } = Typography;

interface Props {
  title?: string;
  isLoading: boolean;
  campaigns: Campaign[];
  count?: number;
  closeHandler?: () => void;
  nextPageHandler?: () => void;
}

const CampaignList: React.FC<Props> = ({
  title,
  isLoading,
  campaigns,
  count,
  closeHandler,
  nextPageHandler,
}) => {
  const { t } = useTranslation();

  const observerRef = useRef(null);

  useEffect(() => {
    let observerRefValue: Element | null = null;

    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          nextPageHandler?.();
        }
      },
      { threshold: 1 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
      observerRefValue = observerRef.current;
    }

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
  }, [observerRef, nextPageHandler]);

  return (
    <div>
      {isLoading
        ? <Skeleton title={{ width: 100 }} paragraph={false} active />
        : !!count
          ? <Title level={5} style={{ margin: '12px 0 20px' }}>
            {t('search.campaigns_count', { count })}
          </Title>
          : !!title &&
          <Title level={5} style={{ margin: '12px 0 20px' }}>{title}</Title>}
      <Row gutter={[32, 16]}>
        {campaigns.map((item, i) => <Col key={i} span={24}>
          <CampaignItem item={item} closeHandler={closeHandler} />
        </Col>)}
        {isLoading && <>
          <Col span={24}><CampaignItem /></Col>
          <Col span={24}><CampaignItem /></Col>
          <Col span={24}><CampaignItem /></Col>
        </>}
      </Row>
      {!isLoading && campaigns.length > 0 && <div ref={observerRef}></div>}
    </div>
  );
}

export default CampaignList;
