import styled from 'styled-components';
import { Col, Input, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import logo from './logo.png';
import Menu from './Menu';
import AuthenticationModal from './AuthenticationModal';
import EarnedMilesModal from './EarnedMiles/EarnedMilesModal';
import SearchDrawer from './Search/SearchDrawer';

const Container = styled.div`
  position: sticky;
  top: 0;
  background-color: #000;
  height: 80px;
  z-index: 20;
  padding-top: 20px;
`;

// const btnClickHandler = () => {
//   const urlSearchParams = new URLSearchParams(window.location.search);
//   const queryParams = Object.fromEntries(urlSearchParams.entries());
//   console.log(queryParams);

//   const newQueries = {
//      ...queryParams,
//      locale: "en"
//   };
//   console.log(newQueries);
// }

const Heading: React.FC = () => {
  const { t } = useTranslation();
  const [isSearchDrawerOpen, setIsSearchDrawerOpen] = useState(false);

  return (
    <Container>
      <Row align={'middle'} justify={{ xs: 'space-evenly', sm: 'space-evenly' }}>
        <Col xs={3} md={{ span: 4, offset: 2 }}>
          <a href="/">
            <img src={logo} style={{ width: 40 }} alt="goodmates-logo" />
          </a>
        </Col>
        <Col xs={14} sm={9} md={{ span: 7, offset: 7 }}>
          <Input
            prefix={<SearchOutlined />}
            placeholder={t('search.searchPlaceholder')}
            onFocus={() => setIsSearchDrawerOpen(true)} />
        </Col>
        <Col xs={3} md={{ span: 2 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Menu />
          <AuthenticationModal />
          <EarnedMilesModal />
          {isSearchDrawerOpen &&
            <SearchDrawer
              isOpen={isSearchDrawerOpen}
              closeHandler={() => setIsSearchDrawerOpen(false)} />}
        </Col>
      </Row>
    </Container>
  );
};

export default Heading;
