import { AxiosInstance } from "axios";
import useSWR from "swr";

import { Campaign } from "models/CommonType";
import { errorFormatter } from 'services/serviceUtils/responseFormatter';
import { Error } from 'services/types/CommonTypes';
import { fetcherWithToken } from "./ApiClient";

export const useCampaign = (id: string, token: string) => {
  const { data, error, isLoading, mutate } = useSWR(
    !!id ? [`${process.env.REACT_APP_DOMAIN}/api/campaigns/${id}/`, token] : null,
    ([url, token]) => fetcherWithToken(url, token));

  return {
    data: data || {},
    isLoading: isLoading,
    isError: error,
    mutate: mutate,
  }
};

export interface CampaignProps {
  joinCampaign: (id: string) => Promise<Campaign>;
}

function createCampaignService(axiosClient: AxiosInstance): CampaignProps {
  return {
    joinCampaign: async (id: string): Promise<Campaign> => {
      return axiosClient.post(`/api/campaigns/${id}/join/`).then(
        (res) => res?.data,
        (err) => {
          const { errors }: { errors: Error[] } = err?.response?.data || {};
          const formattedError = errorFormatter(errors);
          return { error: formattedError };
        }
      );
    },
  };
}

export default createCampaignService;
