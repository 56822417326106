import { isAndroid, isIOS, isMacOs } from "react-device-detect";

const truncateString = (str: string, num: number) => {
  if (str == null)
    return ''

  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

const urlify = (text: string = "") => {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url) => '<a href="' + url + '">' + url + '</a>')
}

const iOSLink = "https://apps.apple.com/hk/app/goodmates/id1559340728";
const androidLink = "https://play.google.com/store/apps/details?id=org.goodmates";

const goToApp = (isGoToWeb = true) => {
  if (isAndroid) {
    const url =
      "intent://www.goodmates.com/#Intent;scheme=https;package=org.goodmates;end";

    window.location.replace(url);
  } else if (isIOS) {
    window.location.replace("goodmates://");

    if (isGoToWeb) {
      setTimeout(() => {
        window.location.replace(iOSLink);
      }, 2000);
    }
  } else if (isMacOs && isGoToWeb) {
    window.location.replace(iOSLink);
  } else if (isGoToWeb) {
    window.location.replace(androidLink);
  }
}

export {
  iOSLink,
  androidLink,
  goToApp,
  truncateString,
  urlify,
};
