import { AxiosInstance } from 'axios';
import { errorFormatter } from 'services/serviceUtils/responseFormatter';
import { Error } from 'services/types/CommonTypes';
import {
  FollowUserResponse,
  NotificationsResponse,
  ResetPasswordAndConfirmInputType,
  UnfollowUserResponse,
  User,
  UserDetailsResponse,
  VerifyEmailResponse
} from 'services/types/ProfileTypes';

type UserDetailsRequestData = Partial<User> | Record<string, string | boolean>;

export interface ProfileProps {
  getUserDetails: () => Promise<UserDetailsResponse>;
  getUsersList: (params: { groupName?: string; sort?: string }) => Promise<UserDetailsResponse>;
  resendVerificationEmail: (email: string | undefined) => Promise<VerifyEmailResponse>;
  resetPasswordAndSendEmail: (email: string) => Promise<VerifyEmailResponse>;
  resetPasswordAndConfirm: (data: ResetPasswordAndConfirmInputType) => Promise<VerifyEmailResponse>;
  updateUserDetails: (id: string, data: UserDetailsRequestData) => Promise<UserDetailsResponse>;
  verifyUserEmail: (key: string | undefined) => Promise<VerifyEmailResponse>;
  followUser: (id: string) => Promise<FollowUserResponse>;
  unfollowUser: (id: string) => Promise<UnfollowUserResponse>;
  getNotifiactions: () => Promise<NotificationsResponse>;
}

function createProfileService(axiosClient: AxiosInstance): ProfileProps {
  return {
    getUserDetails: async (): Promise<UserDetailsResponse> => {
      return axiosClient.get('/api/users/me/').then(
        (res) => res?.data,
        (err) => {
          const { errors }: { errors: Error[] } = err?.response?.data || {};
          const formattedError = errorFormatter(errors);
          return { error: formattedError };
        }
      );
    },
    getUsersList: async ({
      groupName,
      sort = 'first_name',
    }: {
      groupName?: string;
      sort?: string;
    }): Promise<UserDetailsResponse> => {
      return axiosClient.get('/api/users/', { params: { sort, groups__name: groupName } }).then(
        (res) => res?.data,
        (err) => {
          const { errors }: { errors: Error[] } = err?.response?.data || {};
          const formattedError = errorFormatter(errors);
          return { error: formattedError };
        }
      );
    },
    resendVerificationEmail: async (email: string | undefined): Promise<VerifyEmailResponse> => {
      return axiosClient.post('/api/auth/registration/resend-email/', { email }).then(
        (res) => res?.data,
        (err) => {
          const { errors }: { errors: Error[] } = err?.response?.data || {};
          const formattedError = errorFormatter(errors);
          return { error: formattedError };
        }
      );
    },
    resetPasswordAndSendEmail: async (email: string): Promise<VerifyEmailResponse> => {
      return axiosClient.post('/api/auth/password/reset/', { email }).then(
        (res) => res?.data,
        (err) => {
          const { errors }: { errors: Error[] } = err?.response?.data || {};
          const formattedError = errorFormatter(errors);
          return { error: formattedError };
        }
      );
    },
    resetPasswordAndConfirm: async (data: ResetPasswordAndConfirmInputType): Promise<VerifyEmailResponse> => {
      return axiosClient.post('/api/auth/password/reset/confirm/', data).then(
        (res) => res?.data,
        (err) => {
          const { errors }: { errors: Error[] } = err?.response?.data || {};
          const formattedError = errorFormatter(errors);
          return { error: formattedError };
        }
      );
    },
    updateUserDetails: async (id: string, data: UserDetailsRequestData): Promise<UserDetailsResponse> => {
      return axiosClient.patch(`/api/users/${id}/`, data).then(
        (res) => res?.data,
        (err) => {
          const { errors }: { errors: Error[] } = err?.response?.data || {};
          const formattedError = errorFormatter(errors);
          return { error: formattedError };
        }
      );
    },
    verifyUserEmail: async (key: string | undefined): Promise<VerifyEmailResponse> => {
      return axiosClient.post('/api/auth/registration/verify-email/', { key }).then(
        (res) => res?.data,
        (err) => {
          const { errors }: { errors: Error[] } = err?.response?.data || {};
          const formattedError = errorFormatter(errors);
          return { error: formattedError };
        }
      );
    },
    followUser: async (id: string): Promise<FollowUserResponse> => {
      return axiosClient.post(`/api/users/${id}/follow/`).then(
        (res) => res?.data,
        (err) => {
          const { errors }: { errors: Error[] } = err?.response?.data || {};
          const formattedError = errorFormatter(errors);
          return { error: formattedError };
        }
      );
    },
    unfollowUser: async (id: string): Promise<UnfollowUserResponse> => {
      return axiosClient.delete(`/api/users/${id}/follow/`).then(
        (res) => res?.data,
        (err) => {
          const { errors }: { errors: Error[] } = err?.response?.data || {};
          const formattedError = errorFormatter(errors);
          return { error: formattedError };
        }
      );
    },
    getNotifiactions: async (): Promise<NotificationsResponse> => {
      return axiosClient.get(`/api/notifications/?page[number]=1`).then(
        (res) => res?.data,
        (err) => {
          const { errors }: { errors: Error[] } = err?.response?.data || {};
          const formattedError = errorFormatter(errors);
          return { error: formattedError };
        }
      );
    },
  };
}

export default createProfileService;
